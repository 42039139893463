import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import formatDate from '../../utils/formatDate';

const OrderDevlieryStatus = ({ order }) => {
	return (
		<>
			<Row
				className={classNames('g-3 recent-activity-timeline recent-activity-timeline-primary', {
					'pb-x1': true,
					'recent-activity-timeline-past': order.isDelivered,
					'recent-activity-timeline-current': order.isPlaceOrderPaid && !order.isDelivered,
				})}
				key='delivery'
			>
				<Col xs='auto' className='ps-4 ms-2'>
					<div className='ps-2'>
						<div className='icon-item icon-item-sm rounded-circle bg-200 shadow-none'>
							<FontAwesomeIcon icon='truck' className='text-primary' />
						</div>
					</div>
				</Col>
				<Col>
					<Row className={classNames('g-3', { 'border-bottom pb-x1': false })}>
						<Col>
							<div className='mb-2'>
								<h6>发货</h6>
							</div>
							{!order.isPlaceOrderPaid ? (
								<p className='fs-10 text-600 mb-0'>确认收款后发货</p>
							) : !order.isDelivered ? (
								<p className='fs-10 text-600 mb-0'>待发货</p>
							) : (
								<p className='fs-10 text-600 mb-0'>订单已全部发货在 {formatDate(order.deliveredAt)}</p>
							)}
						</Col>
						<Col xs='auto'></Col>
					</Row>
				</Col>
			</Row>
			<Row
				className={classNames('g-3 recent-activity-timeline recent-activity-timeline-primary', {
					'pb-x1': true,
					'recent-activity-timeline-past': order.isAccepted,
					'recent-activity-timeline-current': order.isDelivered && !order.isAccepted,
				})}
				key='accept'
			>
				<Col xs='auto' className='ps-4 ms-2'>
					<div className='ps-2'>
						<div className='icon-item icon-item-sm rounded-circle bg-200 shadow-none'>
							<FontAwesomeIcon icon='fa-box-open' className='text-primary' />
						</div>
					</div>
				</Col>
				<Col>
					<Row className={classNames('g-3', { 'border-bottom pb-x1': false })}>
						<Col>
							<div className='mb-2'>
								<h6>验收</h6>
							</div>
							{!order.isDelivered ? (
								<p className='fs-10 text-600 mb-0'>发货后可验收</p>
							) : !order.isAccepted ? (
								<p className='fs-10 text-600 mb-0'>待验收</p>
							) : (
								<p className='fs-10 text-600 mb-0'>订单已验收在 {formatDate(order.acceptedAt)}</p>
							)}
						</Col>
						<Col xs='auto'></Col>
					</Row>
				</Col>
			</Row>
			<Row
				className={classNames('g-3 recent-activity-timeline recent-activity-timeline-primary', {
					'pb-x1': false,
					'recent-activity-timeline-past': order.isCompleted,
					'recent-activity-timeline-current': !order.isCompleted && order.isDelivered && order.isAccepted,
				})}
				key='complete'
			>
				<Col xs='auto' className='ps-4 ms-2'>
					<div className='ps-2'>
						<div className='icon-item icon-item-sm rounded-circle bg-200 shadow-none'>
							<FontAwesomeIcon icon='fa-thumbs-up' className='text-primary' />
						</div>
					</div>
				</Col>
				<Col>
					<Row className={classNames('g-3', { 'border-bottom pb-x1': false })}>
						<Col>
							<div className='mb-2'>
								<h6>完成</h6>
							</div>
							{order.isCompleted ? <p className='fs-10 text-600 mb-0'>订单已完成在 {formatDate(order.completedAt)}</p> : <p className='fs-10 text-600 mb-0'>待完成订单</p>}
						</Col>
						<Col xs='auto'></Col>
					</Row>
				</Col>
			</Row>
		</>
	);
};

export default OrderDevlieryStatus;
