import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { Badge, Card, Col, Dropdown, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AdvanceTable from '../../../components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from '../../../components/common/advance-table/AdvanceTableFooter';
import CardDropdown from '../../../components/common/CardDropdown';
import Loader from '../../../components/Loader';
import Message from '../../../components/Message';
import useAdvanceTable from '../../../hooks/useAdvanceTable';
import AdvanceTableProvider from '../../../providers/AdvanceTableProvider';
import ApproveModal from './modal/ApproveModal';
import AdvanceTableSearchBox from '../../../components/common/advance-table/AdvanceTableSearchBox';

const ApprovingOrderPendings = ({
	data,
	isLoading,
	isError,
	refetchMyApprovals,
	refetchApproveOrders,
	refetchAcceptingOrders,
	refetchDeliveringOrders,
	isMyApprovalsOrderFetching,
	refetchAROrders,
}) => {
	const [isOpenPendingModal, setIsOpenPendingModal] = useState(false);
	const [modalPendingContent, setModalPendingContent] = useState({});

	const columns = useMemo(
		() => [
			{
				accessorKey: 'approval.title',
				header: '审批事项',
				meta: {
					headerProps: { className: 'sticky-column text-900' },
					cellProps: {
						className: 'fw-semibold',
					},
				},
				cell: ({ row: { original } }) => {
					const { approval } = original;
					return (
						<Link
							onClick={(e) => {
								setModalPendingContent(original);
								setIsOpenPendingModal(true);
							}}
							className='text-danger'
						>
							{approval?.title}
						</Link>
					);
				},
			},
			{
				accessorKey: 'customerInfo.customerName',
				header: '客户名称',
				meta: {
					headerProps: { className: 'pe-3 text-900 text-start' },
					cellProps: { className: 'text-start' },
				},
				cell: ({ row: { original } }) => {
					const { customerInfo } = original;
					return <span className='text-danger'>{customerInfo.customerName}</span>;
				},
			},
			{
				accessorKey: 'approvalStatus',
				header: '审批状态',
				meta: {
					headerProps: { className: 'pe-3 text-900' },
				},
				cell: () => {
					return (
						<Badge bg='danger' className='me-2'>
							<span className='fs-11'>待审批</span>
						</Badge>
					);
				},
			},

			{
				accessorKey: 'applicantName',
				header: '申请人',
				cell: ({ row: { original } }) => {
					const { createdUser } = original;
					return <span className='fs-11'>{createdUser.name}</span>;
				},
			},
			{
				accessorKey: 'assistantName',
				header: '销售助理',
				cell: ({ row: { original } }) => {
					const { assistantUser } = original;
					return <span className='fs-11'>{assistantUser.name}</span>;
				},
			},
			{
				accessorKey: 'createdAt',
				header: '创建时间',
				meta: {
					headerProps: {
						className: 'text-end text-900',
					},
					cellProps: {
						className: 'text-end',
					},
				},
				cell: ({ row: { original } }) => {
					return <span>{moment(original.createdAt).format('YYYY-MM-DD HH:mm')}</span>;
				},
			},
			{
				accessorKey: 'none',
				header: '',
				enableSorting: false,
				meta: {
					cellProps: {
						className: 'text-end py-2',
					},
				},
				cell: ({ row: { original } }) => {
					const { approval } = original;
					return (
						<CardDropdown drop='start'>
							<div className='py-2'>
								<Dropdown.Item href={`/approval/order/${approval?.detail.orderOID}/detail/${approval?._id}`}>查看</Dropdown.Item>
							</div>
						</CardDropdown>
					);
				},
			},
		],
		[]
	);

	const table = useAdvanceTable({
		data,
		columns: columns,
		sortable: true,
		pagination: true,
		perPage: 7,
		initialState: {
			sorting: [{ id: 'createdAt', desc: true }],
		},
	});

	return (
		<>
			<AdvanceTableProvider {...table}>
				<Row className='flex-center my-3'>
					<Col xs='auto' sm={6} lg={4}>
						<AdvanceTableSearchBox />
					</Col>
				</Row>
				<AdvanceTable
					headerClassName='bg-200 text-nowrap align-middle font-sans-serif'
					rowClassName='btn-reveal-trigger text-nowrap align-middle'
					tableProps={{
						className: 'fs-10 fw-semibold mb-0 overflow-hidden',
					}}
				/>
				{!isLoading && data.length === 0 && (
					<div className='my-5 w-100 text-center'>
						<p className=''>暂无需要审批的订单</p>
					</div>
				)}
				{isLoading && <Loader />}
				{isError && <Message animation='border' variant='primary' className='mt-3' />}
				<Card.Footer>
					<AdvanceTableFooter rowInfo navButtons />
				</Card.Footer>
			</AdvanceTableProvider>
			<ApproveModal
				setIsOpenPendingModal={setIsOpenPendingModal}
				isOpenPendingModal={isOpenPendingModal}
				modalPendingContent={modalPendingContent}
				refetchMyApprovals={refetchMyApprovals}
				refetchAROrders={refetchAROrders}
				refetchAcceptingOrders={refetchAcceptingOrders}
				refetchDeliveringOrders={refetchDeliveringOrders}
				isLoading={isLoading}
				isMyApprovalsOrderFetching={isMyApprovalsOrderFetching}
			/>
		</>
	);
};

export default ApprovingOrderPendings;
