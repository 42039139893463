import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, ListGroup, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Message from '../../components/Message';
import { useAcceptOrderMutation, useAddAccountsReceivableMutation, useCompleteScheduleNodeMutation, useDeliverOrderMutation } from '../../slices/ordersApiSlice';
import formatDate from '../../utils/formatDate';

const OrderDetailOperationCard = ({
	order: { _id, scheduleNodes, totalPrice, isPlaceOrderPaid, isDelivered, deliveredAt, isAccepted, acceptedAt, isCompleted, completedAt, approvalStatus },
	refetch,
	placeOrderNode,
	isOrderFetching,
}) => {
	const { userInfo } = useSelector((state) => state.auth);

	const [deliverOrder, { isLoading: loadingDeliver, isSuccess: deliverSuccess }] = useDeliverOrderMutation();
	const [acceptOrder, { isLoading: loadingAccept }] = useAcceptOrderMutation();
	const [addOrderAccountsReceivable, { isLoading: savingAR }] = useAddAccountsReceivableMutation();

	const [completeScheduleNode] = useCompleteScheduleNodeMutation();

	const deliverHandler = async () => {
		try {
			// 检查订单是否有发货后收款计划节点
			if (scheduleNodes.length === 0) {
				await deliverOrder(_id).unwrap();
				toast.success('订单已发货/提供服务');
				await addOrderAccountsReceivable({
					orderId: _id,
					details: { accountsReceivable: 0, title: '发货/提供服务', paymentDays: 0, totalPrice: totalPrice },
				}).unwrap();
				refetch();
			} else {
				const deliverScheduleNode = scheduleNodes.filter((node) => node.title === '发货/提供服务' && !node.isCompleted);
				console.log(deliverScheduleNode);
				if (deliverScheduleNode.length !== 0 && deliverScheduleNode[0].paymentAmount > 0) {
					const deliveryNodeUpdatedOrder = await completeScheduleNode({ orderId: _id, scheduleNode: deliverScheduleNode[0]._id }).unwrap();

					// 检查是否有未发货的节点，如果没有则说明订单发货全部完成
					if (deliveryNodeUpdatedOrder.scheduleNodes.filter((node) => node.title === '发货/提供服务' && !node.isCompleted).length === 0) {
						await deliverOrder(deliveryNodeUpdatedOrder._id).unwrap();
					}

					//await deliverOrder(_id).unwrap();
					toast.success('订单已发货/提供服务');
					await addOrderAccountsReceivable({
						orderId: _id,
						details: {
							accountsReceivable: deliverScheduleNode[0].paymentAmount,
							title: deliverScheduleNode[0].title,
							paymentDays: deliverScheduleNode[0].paymentDays,
							totalPrice: totalPrice,
							scheduleNode: deliverScheduleNode[0]._id,
						},
					}).unwrap();
					refetch();
				} else {
					await deliverOrder(_id).unwrap();
					toast.success('订单已发货/提供服务');
					await addOrderAccountsReceivable({
						orderId: _id,
						details: { accountsReceivable: 0, title: '发货/提供服务', paymentDays: 0, totalPrice: totalPrice, scheduleNode: deliverScheduleNode[0]._id },
					}).unwrap();
					refetch();
				}
			}
		} catch (err) {
			toast.error(err?.data?.message || err.message);
		}
	};

	const acceptHandler = async () => {
		try {
			// 检查订单是否有验收后收款计划节点
			if (scheduleNodes.length === 0) {
				await acceptOrder(_id).unwrap();
				toast.success('订单已验收完毕');
				await addOrderAccountsReceivable({
					orderId: _id,
					details: { accountsReceivable: 0, title: '验收', paymentDays: 0, totalPrice: totalPrice },
				}).unwrap();
				refetch();
			} else {
				const acceptScheduleNode = scheduleNodes.filter((node) => node.title === '验收' && !node.isCompleted);

				if (acceptScheduleNode.length !== 0 && acceptScheduleNode[0].paymentAmount > 0) {
					console.log(acceptScheduleNode[0]._id);
					const acceptNodeUpdatedOrder = await completeScheduleNode({ orderId: _id, scheduleNode: acceptScheduleNode[0]._id }).unwrap();
					console.log(acceptNodeUpdatedOrder);
					// 检查是否有未验收的节点，如果没有则说明订单验收全部完成
					if (acceptNodeUpdatedOrder.scheduleNodes.filter((node) => node.title === '验收' && !node.isCompleted).length === 0) {
						await acceptOrder(acceptNodeUpdatedOrder._id).unwrap();
					}

					toast.success('订单已验收完毕');
					await addOrderAccountsReceivable({
						orderId: _id,
						details: {
							accountsReceivable: acceptScheduleNode[0].paymentAmount,
							title: '验收',
							paymentDays: acceptScheduleNode[0].paymentDays,
							totalPrice: totalPrice,
							scheduleNode: acceptScheduleNode[0]._id,
						},
					}).unwrap();
					refetch();
				} else {
					await acceptOrder(_id).unwrap();
					toast.success('订单已验收完毕');
					await addOrderAccountsReceivable({
						orderId: _id,
						details: { accountsReceivable: 0, title: '验收', paymentDays: 0, totalPrice: totalPrice, scheduleNode: acceptScheduleNode[0]._id },
					}).unwrap();
					refetch();
				}
			}
		} catch (err) {
			toast.error(err?.data?.message || err.message);
		}
	};

	return (
		<Card className='sticky-sidebar z-1'>
			<Card.Header as='h6' className='bg-body-tertiary'>
				<Card.Title className='mb-0'>订单标记</Card.Title>
			</Card.Header>
			<ListGroup variant='flush'>
				{userInfo.role === 'buyer' && !isDelivered && (
					<ListGroup.Item>
						<p>
							<strong>操作订单</strong>
						</p>
						<Button onClick={deliverHandler} className='m-3' disabled={placeOrderNode && !placeOrderNode[0]?.isPaid} hidden={deliverSuccess}>
							<FontAwesomeIcon icon='fa-solid fa-stamp' className='me-2' />
							标记为已发货 {loadingDeliver && <Spinner size='sm' />}
							{savingAR && <Spinner size='sm' />}
						</Button>
					</ListGroup.Item>
				)}
				{['sale', 'assistant', 'manager'].includes(userInfo.role) && !isAccepted && ['approved', 'systemApproved'].includes(approvalStatus) && (
					<ListGroup.Item>
						<p>
							<strong>操作订单</strong>
						</p>
						<Button onClick={acceptHandler} className='m-3' disabled={!isPlaceOrderPaid || !isDelivered}>
							{!isPlaceOrderPaid || !isDelivered ? (
								<>
									<FontAwesomeIcon icon='fa-truck-ramp-box' className='me-2' />
									发货后可验收
								</>
							) : (
								<>
									<FontAwesomeIcon icon='fa-regular fa-square-check' className='me-2' />
									验收完成
								</>
							)}
							{loadingAccept && <Spinner size='sm' />}
							{savingAR && <Spinner size='sm' />}
						</Button>
					</ListGroup.Item>
				)}

				{['approved', 'systemApproved'].includes(approvalStatus) && (
					<ListGroup.Item>
						{isOrderFetching && <Message>正在更新订单...</Message>}
						<strong>物流标记</strong>
						<ul className='timeline-order m-3'>
							<li className='timeline-item'>
								<strong>
									{isDelivered ? (
										<Message variant='success'>
											<span className='fs-10'>
												<FontAwesomeIcon icon='fa-truck-fast' className='me-2' />
												订单已全部发货在 {formatDate(deliveredAt)}
											</span>
										</Message>
									) : (
										<Message variant='warning'>
											<span className='fs-10'>
												<FontAwesomeIcon icon='fa-solid fa-cubes' className='me-2' />
												未发货
											</span>
										</Message>
									)}
								</strong>
							</li>

							<li className='timeline-item '>
								<strong>
									{isAccepted ? (
										<Message variant='success'>
											<span className='fs-10'>
												<FontAwesomeIcon icon='fa-box-open' className='me-2' />
												订单已验收在 {formatDate(acceptedAt)}
											</span>
										</Message>
									) : (
										<Message variant='warning'>
											<span className='fs-10'>
												<FontAwesomeIcon icon='fa-clipboard-question' className='me-2' />
												等待验收
											</span>
										</Message>
									)}
								</strong>
							</li>

							<li className='timeline-item'>
								<strong>
									{isCompleted ? (
										<Message variant='success'>
											<span className='fs-10'>
												<FontAwesomeIcon icon='fa-thumbs-up' className='me-2' />
												订单已完成在 {formatDate(completedAt)}
											</span>
										</Message>
									) : (
										<Message variant='warning'>
											<span className='fs-10'>
												<FontAwesomeIcon icon='fa-clipboard-question' className='me-2' />
												待完成订单
											</span>
										</Message>
									)}
								</strong>
							</li>
						</ul>
					</ListGroup.Item>
				)}
			</ListGroup>
		</Card>
	);
};

export default OrderDetailOperationCard;
