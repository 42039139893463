import React from 'react';
import { Col, ListGroup, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SubtleBadge from '../../../../../components/common/SubtleBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';

const ProductListDetail = ({ order: { orderItems } }) => {
	const { userInfo } = useSelector((state) => state.auth);
	return (
		<ListGroup variant='flush'>
			<ListGroup.Item>
				<h5 className='my-3'>
					<strong>产品列表</strong>
				</h5>
				<ListGroup.Item className='bg-warning-subtle'>
					<Row className='text-warning'>
						<Col>
							<strong>货号</strong>
						</Col>
						<Col>
							<strong>产品名称</strong>
						</Col>
						<Col>
							<strong>数量</strong>
						</Col>
						{userInfo._id === '66260f270bdc36982bc02ace' && (
							<Col>
								<strong>进价</strong>
							</Col>
						)}

						<Col>
							<strong>单价</strong>
						</Col>
						<Col>
							<strong>总价</strong>
						</Col>
					</Row>
				</ListGroup.Item>
				{orderItems?.map((item, index) => (
					<ListGroup.Item key={index}>
						<Row>
							<Col>
								<Link className='text-800' to=''>
									{item.productCode}
									{item.sellingPrice < item.minimumSellingPrice && <FontAwesomeIcon icon='star' className='ms-2 mb-1 text-danger fs-11' />}
								</Link>
							</Col>
							<Col>
								<Link className='text-800'>{item.name}</Link>
							</Col>
							<Col>{item.qty}</Col>
							{userInfo._id === '66260f270bdc36982bc02ace' && <Col>¥{item.purchasingPrice}</Col>}
							<Col>
								{item.sellingPrice < item.minimumSellingPrice ? (
									<>
										<del className='me-2 fw-bolder'>¥{item.minimumSellingPrice.toFixed(2)}</del>
										<span className='me-2 fw-bolder text-danger'>¥{item.sellingPrice.toFixed(2)}</span>
										<p>
											<SubtleBadge bg='danger'>降价{Number(item.sellingPrice - item.minimumSellingPrice).toFixed(2)}元</SubtleBadge>
										</p>
									</>
								) : (
									<span className='me-2 fw-bolder text-800'>¥{item.sellingPrice.toFixed(2)}</span>
								)}
							</Col>
							<Col>
								{item.sellingPrice < item.minimumSellingPrice ? (
									<>
										<del className='me-2'>¥{(item.qty * item.minimumSellingPrice).toFixed(2)}</del>
										<span className='fw-bolder text-danger'>¥{(item.qty * item.sellingPrice).toFixed(2)}</span>
										<p>
											<SubtleBadge bg='danger'>共计优惠{Number(item.minimumSellingPrice * item.qty - item.sellingPrice * item.qty).toFixed(2)}元</SubtleBadge>
										</p>
									</>
								) : (
									<span>¥{(item.qty * item.sellingPrice).toFixed(2)}</span>
								)}
							</Col>
						</Row>
					</ListGroup.Item>
				))}
			</ListGroup.Item>
		</ListGroup>
	);
};

export default ProductListDetail;
