import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Badge, Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Flex from '../../../../components/common/Flex';
import Message from '../../../../components/Message';
import { useAcceptOrderMutation, useAddAccountsReceivableMutation, useCompleteScheduleNodeMutation, useGetOrderDetailsQuery } from '../../../../slices/ordersApiSlice';
import CustomerDetail from './common/CustomerDetail';
import ModalLoading from './common/ModalLoading';
import OrderNotesDetail from './common/OrderNotesDetail';
import ProductListDetail from './common/ProductListDetail';
import PaymentDetail from './common/PaymentDetail';
import EventModalMediaContent from '../../../../components/EventModalMediaContent';
import OrderPaymentScheduleNode from '../../../order/OrderPaymentScheduleNode';
import OrderDevlieryStatus from '../../../order/OrderDevlieryStatus';

const AcceptModal = ({ setIsOpenPendingModal, isOpenPendingModal, orderOId, refetchAcceptingOrders, isAcceptingOrderFetching, refetchAROrders }) => {
	const { isDark } = useSelector((state) => state.config);
	const [acceptOrder, { isLoading: loadingAccept }] = useAcceptOrderMutation();
	const [addOrderAccountsReceivable, { isLoading: savingAR }] = useAddAccountsReceivableMutation();
	const [completeScheduleNode] = useCompleteScheduleNodeMutation();
	const { data: order = [], refetch, isLoading, isFetching: isOrderFetching } = useGetOrderDetailsQuery(orderOId, { skip: !isOpenPendingModal });

	const handleClose = () => {
		setIsOpenPendingModal(!isOpenPendingModal);
	};

	const { _id, orderId, customerInfo, scheduleNodes, totalPrice, approvalStatus, isDelivered, isAccepted, isPlaceOrderPaid } = order;

	const acceptHandler = async () => {
		try {
			// 检查订单是否有验收后收款计划节点
			if (scheduleNodes.length === 0) {
				await acceptOrder(_id).unwrap();
				toast.success('订单已验收完毕');
				await addOrderAccountsReceivable({
					orderId: _id,
					details: { accountsReceivable: 0, title: '验收', paymentDays: 0, totalPrice: totalPrice },
				}).unwrap();
				refetch();
			} else {
				const acceptScheduleNode = scheduleNodes.filter((node) => node.title === '验收' && !node.isCompleted);

				if (acceptScheduleNode.length !== 0 && acceptScheduleNode[0].paymentAmount > 0) {
					const acceptNodeUpdatedOrder = await completeScheduleNode({ orderId: _id, scheduleNode: acceptScheduleNode[0]._id }).unwrap();

					// 检查是否有未验收的节点，如果没有则说明订单验收全部完成
					if (acceptNodeUpdatedOrder.scheduleNodes.filter((node) => node.title === '验收' && !node.isCompleted).length === 0) {
						await acceptOrder(acceptNodeUpdatedOrder._id).unwrap();
					}

					toast.success('订单已验收完毕');
					await addOrderAccountsReceivable({
						orderId: _id,
						details: {
							accountsReceivable: acceptScheduleNode[0].paymentAmount,
							title: '验收',
							paymentDays: acceptScheduleNode[0].paymentDays,
							totalPrice: totalPrice,
							scheduleNode: acceptScheduleNode[0]._id,
						},
					}).unwrap();
					refetch();
				} else {
					await acceptOrder(_id).unwrap();
					toast.success('订单已验收完毕');
					await addOrderAccountsReceivable({
						orderId: _id,
						details: { accountsReceivable: 0, title: '验收', paymentDays: 0, totalPrice: totalPrice, scheduleNode: acceptScheduleNode[0]._id },
					}).unwrap();
					refetch();
				}
			}
			refetchAcceptingOrders();
			refetchAROrders();
		} catch (err) {
			toast.error(err?.data?.message || err.message);
		}
	};

	return (
		<Modal show={isOpenPendingModal} onHide={handleClose} contentClassName='border' centered size='xl' scrollable>
			<Modal.Header closeButton closeVariant={isDark ? 'white' : undefined} className='bg-body-tertiary px-x1 border-bottom-0'>
				{isLoading || isOrderFetching ? (
					<Spinner className='text-primary' />
				) : (
					<Modal.Title>
						<Flex>
							<h5 className='mb-0'>{orderId}</h5>
							{approvalStatus === 'inProcessOfApproval' && (
								<Badge bg='danger' className='ms-2 fs-10'>
									待审批
								</Badge>
							)}
						</Flex>

						{customerInfo?.customerName && (
							<p className='mb-0 fs-10 mt-1 fw-normal'>
								客户名称：<a href='#!'>{customerInfo.customerName}</a>
							</p>
						)}
					</Modal.Title>
				)}
			</Modal.Header>
			<Modal.Body className='px-x1 pb-x1 pt-1 fs-10'>
				{isLoading || isOrderFetching ? (
					<ModalLoading />
				) : (
					<>
						<Row>
							<Col>
								<CustomerDetail order={order} />
							</Col>
							<Col sm={7}>
								<PaymentDetail order={order} />
								{isAcceptingOrderFetching && <Message>正在更新应收账款...</Message>}
							</Col>
						</Row>
						<Row>
							<Col>
								<EventModalMediaContent icon='fa-regular fa-comment-dots' heading='订单备注'>
									<OrderNotesDetail order={order} />
								</EventModalMediaContent>
							</Col>
							{order.paymentMethod === '全额支付' && (
								<Col sm={7}>
									<EventModalMediaContent icon='fa-regular fa-comment-dots' heading='物流状态'>
										{!isLoading && !isOrderFetching && <OrderDevlieryStatus order={order} />}
									</EventModalMediaContent>
								</Col>
							)}
							{order.paymentMethod === '部分支付' && (
								<Col sm={7}>
									<EventModalMediaContent icon='fa-regular fa-comment-dots' heading='尾款计划'>
										{!isLoading && !isOrderFetching && <OrderPaymentScheduleNode order={order} refetch={refetch} />}
									</EventModalMediaContent>
								</Col>
							)}
						</Row>
						<ProductListDetail order={order} />
					</>
				)}
			</Modal.Body>

			<Modal.Footer className='bg-body-tertiary px-x1 border-top-0'>
				<>
					{!isAccepted && (
						<Button onClick={acceptHandler} disabled={!isPlaceOrderPaid || !isDelivered} size='sm'>
							<FontAwesomeIcon icon='fa-regular fa-square-check' className='me-2' />
							验收完成{loadingAccept && <Spinner size='sm' />}
							{savingAR && <Spinner size='sm' />}
						</Button>
					)}

					<Button as={Link} to={`/order/detail/${_id}`} variant='falcon-primary' size='sm'>
						<span>查看更多细节</span>
						<FontAwesomeIcon icon='angle-right' className='fs-11 ms-1' />
					</Button>
				</>
			</Modal.Footer>
		</Modal>
	);
};

export default AcceptModal;
