import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import timeZone from 'moment-timezone';
import 'moment/locale/zh-cn';
import moment from 'moment/moment.js';
import React, { useState } from 'react';
import { Badge, Button, Col, Row, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import SubtleBadge from '../../components/common/SubtleBadge';
import { usePayOrdersMutation } from '../../slices/ordersApiSlice';

const OrderPaymentScheduleNode = ({ order: { _id, orderId, scheduleNodes, accountsReceivables, isApproved }, isLast, refetch }) => {
	const [payOrder, { isLoading: loadingPay }] = usePayOrdersMutation();

	const onPayOrder = async (accountsReceivable, title, scheduleNode, orderOId, orderId) => {
		await payOrder({ orderOId, details: { payAccountsReceivable: accountsReceivable, title, scheduleNode } }).unwrap();
		toast.success(orderId + '订单' + title + '款项已核销!');
		refetch();
	};

	const [currentButtonValue, setCurrentButtonValue] = useState('');
	const { userInfo } = useSelector((state) => state.auth);

	return (
		<>
			{scheduleNodes &&
				accountsReceivables &&
				scheduleNodes.length !== 0 &&
				accountsReceivables.length !== 0 &&
				scheduleNodes.map((schedule, index) => {
					const ar = accountsReceivables.filter((a) => a.scheduleNode === schedule._id).length !== 0 ? accountsReceivables.filter((a) => a.scheduleNode === schedule._id)[0] : {};
					const calculatedDays = timeZone.tz(moment(ar?.expire_time), 'Asia/Shanghai').diff(timeZone.tz(moment(), 'Asia/Shanghai'), 'days');
					return (
						<Row
							className={classNames('g-3 recent-activity-timeline recent-activity-timeline-primary', {
								'pb-x1': index !== schedule.length - 1,
								'recent-activity-timeline-past': schedule.isCompleted,
								'recent-activity-timeline-current':
									accountsReceivables.filter((a) => a.scheduleNode === schedule._id).length !== 0 && !accountsReceivables.filter((a) => a.scheduleNode === schedule._id)[0].isPaid,
							})}
							key={index}
						>
							<Col xs='auto' className='ps-4 ms-2'>
								<div className='ps-2'>
									<div className='icon-item icon-item-sm rounded-circle bg-200 shadow-none'>
										<FontAwesomeIcon icon={schedule.icon} className='text-primary' />
									</div>
								</div>
							</Col>
							<Col>
								<Row className={classNames('g-3', { 'border-bottom pb-x1': !isLast })}>
									<Col>
										<div className='mb-2'>
											<h6>
												{schedule.title}
												<SubtleBadge className='ms-2'>{schedule.paymentPercent}%</SubtleBadge>
												{schedule.isCompleted &&
													(ar && ar.isPaid ? (
														<Badge className='ms-2' bg='success'>
															已收款
														</Badge>
													) : (
														isApproved && (
															<Badge className='ms-2' bg='warning'>
																待确认
															</Badge>
														)
													))}
											</h6>
										</div>
										<p className='fs-10 text-600 mb-0'>
											<span className='me-3'>账期: {schedule.paymentDays}天</span>
											<span className='me-3'>金额: ¥{schedule.paymentAmount}</span>
											{ar && schedule.isCompleted && !ar?.isPaid && (
												<>
													<span className={classNames('fw-semibold', calculatedDays < 7 ? 'text-danger' : 'text-warning', '')}>
														{timeZone.tz(moment(ar?.expire_time), 'Asia/Shanghai').format('YYYY-MM-DD')}到期
													</span>
													<span className={classNames(' fw-semibold', calculatedDays < 7 ? 'text-danger' : 'text-warning')}>
														{calculatedDays < 0 ? (
															<span>
																超期 {calculatedDays} 天 <FontAwesomeIcon icon='fa-calendar' beat className='text-danger' />
															</span>
														) : calculatedDays === 0 ? (
															<span>
																今日到期，请及时催收款项!
																<FontAwesomeIcon icon='fa-triangle-exclamation' beat className='text-danger ' />
															</span>
														) : (
															`剩余 ${timeZone.tz(moment(ar?.expire_time), 'Asia/Shanghai').diff(timeZone.tz(moment(), 'Asia/Shanghai'), 'days')} 天`
														)}
													</span>
												</>
											)}
										</p>

										{loadingPay && ar.title === currentButtonValue && <Spinner size='sm' className='ms-2 text-primary' />}
									</Col>
									<Col xs='auto'>
										{userInfo?.role === 'finance' && schedule.isCompleted && !ar.isPaid && (
											<Button
												size='sm'
												className='fs-11'
												variant='outline-warning'
												onClick={(e) => {
													setCurrentButtonValue(e.currentTarget.value);
													onPayOrder(
														accountsReceivables.filter((node) => node.scheduleNode === schedule._id && !node.isPaid)[0].accountsReceivable,
														accountsReceivables.filter((node) => node.scheduleNode === schedule._id && !node.isPaid)[0].title,
														accountsReceivables.filter((node) => node.scheduleNode === schedule._id && !node.isPaid)[0].scheduleNode,
														_id,
														orderId
													);
												}}
												value={ar.scheduleNode}
											>
												<FontAwesomeIcon icon='fa-solid fa-stamp' className='me-1' />
												已收款
											</Button>
										)}
										{ar ? (
											schedule.isCompleted &&
											ar?.isPaid && (
												<>
													<p className='fs-10 text-600 mb-0'>{timeZone.tz(moment(ar?.paidAt), 'Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss')}</p>
												</>
											)
										) : (
											<></>
										)}
									</Col>
								</Row>
							</Col>
						</Row>
					);
				})}
		</>
	);
};

export default OrderPaymentScheduleNode;
