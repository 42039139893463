/* eslint-disable react/jsx-pascal-case */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Tooltip } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useMemo, useState } from 'react';
import { Button, Card, Col, Dropdown, Row } from 'react-bootstrap';
import { useCreateProductMutation, useDeleteProductMutation, useGetProductsQuery, useUpdateProductMutation } from '../../slices/productsApiSlice';
//Import Material React Table Translations
import { MRT_Localization_ZH_HANS } from 'material-react-table/locales/zh-Hans';
import { useSelector } from 'react-redux';

import CardDropdown from '../../components/common/CardDropdown';
import formatDate from '../../utils/formatDate';
import ProductBatchImportModal from './ProductBatchImportModal';

const ProductListScreen = () => {
	const [validationErrors, setValidationErrors] = useState({});
	const { userInfo } = useSelector((state) => state.auth);
	const [showImportDataModal, setShowImportDataModal] = useState(false);

	const [createProduct, { isPending: isCreatingProduct }] = useCreateProductMutation();

	const { data: fetchedProducts = [], isError: isLoadingProductsError, isFetching: isFetchingProducts, isLoading: isLoadingProducts, refetch } = useGetProductsQuery();

	const [updateProduct, { isPending: isUpdatingProduct }] = useUpdateProductMutation();

	const [deleteProduct, { isPending: isDeletingProduct }] = useDeleteProductMutation();

	const columns = useMemo(
		() => [
			{
				accessorKey: 'productCode',
				header: '货号',
				enableHiding: false,
				visibleInShowHideMenu: false,
				enableClickToCopy: true,
				muiEditTextFieldProps: {
					required: true,
					error: !!validationErrors?.productCode,
					helperText: validationErrors?.productCode,

					onFocus: () =>
						setValidationErrors({
							...validationErrors,
							firstName: undefined,
						}),
				},
			},
			{
				accessorKey: 'name',
				header: '品名',
				enableHiding: false,
				visibleInShowHideMenu: false,
				enableClickToCopy: true,
				muiEditTextFieldProps: {
					required: true,
					error: !!validationErrors?.name,
					helperText: validationErrors?.name,
					onFocus: () =>
						setValidationErrors({
							...validationErrors,
							name: undefined,
						}),
				},
			},
			{
				accessorKey: 'brand',
				header: '品牌',
				enableClickToCopy: true,
				muiEditTextFieldProps: {
					required: true,
					error: !!validationErrors?.brand,
					helperText: validationErrors?.brand,
					onFocus: () =>
						setValidationErrors({
							...validationErrors,
							brand: undefined,
						}),
				},
			},
			{
				accessorKey: 'category',
				header: '种类',
				editVariant: 'select',
				enableClickToCopy: true,
				editSelectOptions: ['设备', '试剂', '耗材', '服务'],
				muiEditTextFieldProps: {
					select: true,
					error: !!validationErrors?.category,
					helperText: validationErrors?.category,
					onFocus: () =>
						setValidationErrors({
							...validationErrors,
							category: undefined,
						}),
				},
			},
			{
				accessorKey: 'supplierName',
				header: '厂家',
				enableClickToCopy: true,
				muiEditTextFieldProps: {
					required: true,
					error: !!validationErrors?.supplierName,
					helperText: validationErrors?.supplierName,
					onFocus: () =>
						setValidationErrors({
							...validationErrors,
							supplierName: undefined,
						}),
				},
			},
			{
				accessorKey: 'specification',
				header: '规格',
				enableClickToCopy: true,
				muiEditTextFieldProps: {
					required: true,
					error: !!validationErrors?.specification,
					helperText: validationErrors?.specification,
					onFocus: () =>
						setValidationErrors({
							...validationErrors,
							specification: undefined,
						}),
				},
			},
			{
				accessorKey: 'countInStock',
				header: '库存',
				enableHiding: false,
				enableClickToCopy: true,
				visibleInShowHideMenu: false,
				muiEditTextFieldProps: {
					required: true,
					error: !!validationErrors?.countInStock,
					helperText: validationErrors?.countInStock,
					onFocus: () =>
						setValidationErrors({
							...validationErrors,
							countInStock: undefined,
						}),
					onChange: (event) => {
						let value = event.target.value;
						let newCountInStock = Number.parseFloat(value);
						//validation logic
						if (!newCountInStock) {
							setValidationErrors((prev) => ({ ...prev, countInStock: '请填写库存' }));
						} else if (newCountInStock < 0) {
							setValidationErrors({
								...validationErrors,
								countInStock: '库存一定要大于等于0',
							});
						} else {
							delete validationErrors.countInStock;
							setValidationErrors({ ...validationErrors });
						}
					},
				},
			},
			{
				accessorKey: 'minimumSellingPrice',
				header: '单价',
				enableEditing: true,
				visibleInShowHideMenu: false,
				muiEditTextFieldProps: {
					required: true,
					error: !!validationErrors?.minimumSellingPrice,
					helperText: validationErrors?.minimumSellingPrice,
					onFocus: () => {
						setValidationErrors({
							...validationErrors,
							minimumSellingPrice: undefined,
						});
					},
					onChange: (event) => {
						let newMinimumSellingPrice = Number.parseFloat(event.target.value);
						//validation logic
						//console.log(newMinimumSellingPrice);
						if (!newMinimumSellingPrice) {
							setValidationErrors((prev) => ({ ...prev, purchasingPrice: '请填写单价' }));
						} else if (newMinimumSellingPrice < 0) {
							setValidationErrors({
								...validationErrors,
								minimumSellingPrice: '单价一定要大于等于0',
							});
						} else {
							//console.log(validationErrors);
							delete validationErrors.minimumSellingPrice;
							setValidationErrors({ ...validationErrors, minimumSellingPrice: undefined });
						}
					},
				},
			},
			{
				accessorKey: 'purchasingPrice',
				header: '进价',
				enableEditing: ['ruijun.nie@genechamp.com', 'irene.ding@genechamp.com'].includes(userInfo.email),
				visibleInShowHideMenu: false,
				muiEditTextFieldProps: {
					required: true,
					error: !!validationErrors?.purchasingPrice,
					helperText: validationErrors?.purchasingPrice,
					onChange: (event) => {
						let value = event.target.value;
						let newPurchasingPrice = Number.parseFloat(value);
						//validation logic
						if (!newPurchasingPrice) {
							setValidationErrors((prev) => ({ ...prev, purchasingPrice: '请填写进价' }));
						} else if (newPurchasingPrice < 0) {
							setValidationErrors({
								...validationErrors,
								purchasingPrice: '进价一定要大于等于0',
							});
						} else {
							delete validationErrors.purchasingPrice;
							setValidationErrors({ ...validationErrors });
						}
					},
				},
			},
			{
				accessorKey: 'user.name',
				header: '创建人',
				enableEditing: false,
				visibleInShowHideMenu: false,
				Edit: () => null,
			},
			{
				accessorKey: 'createdAt',
				header: '创建时间',
				enableEditing: false,
				visibleInShowHideMenu: false,
				Cell: ({ cell }) => <span>{cell.getValue('createdAt') && formatDate(cell.getValue('createdAt'))}</span>,
				Edit: () => null,
			},
			{
				accessorKey: '_id',
				header: 'Id',
				enableEditing: false,
				visibleInShowHideMenu: false,
				Edit: () => null,
			},
		],
		[userInfo.email, validationErrors]
	);

	// CREATE action
	const handleCreateProduct = async ({ values, table }) => {
		let newValidationErrors = validateProduct(values);
		if (Object.values(newValidationErrors).some((x) => x === '必填项！')) {
			setValidationErrors(newValidationErrors);
			return;
		}
		setValidationErrors({});
		await createProduct(values);
		table.setCreatingRow(null); //exit creating mode
		refetch();
	};

	// UPDATE action
	const handleSaveProduct = async ({ values, table }) => {
		let newValidationErrors = validateProduct(values);
		//console.log(Object.values(newValidationErrors));
		if (Object.values(newValidationErrors).some((x) => x === '必填项！')) {
			setValidationErrors(newValidationErrors);
			return;
		}
		setValidationErrors({});
		await updateProduct(values);
		table.setEditingRow(null); //exit editing mode
		refetch();
	};

	// DELETE action
	const openDeleteConfirmModal = async (row) => {
		if (window.confirm('你确定要删除这个产品吗？')) {
			await deleteProduct(row.original._id);
			refetch();
		}
	};

	const table = useMaterialReactTable({
		columns: ['ruijun.nie@genechamp.com', 'irene.ding@genechamp.com'].includes(userInfo.email) ? columns : columns.filter((x) => x.accessorKey !== 'purchasingPrice'),
		data: fetchedProducts,
		createDisplayMode: 'modal', // ('modal', and 'custom' are also available)
		editDisplayMode: 'modal', // ('modal', 'cell', 'table', and 'custom' are also available)
		enableEditing: true,
		enableRowNumbers: true,
		getRowId: (row) => row._id,
		enableFullScreenToggle: false,
		localization: MRT_Localization_ZH_HANS,
		initialState: {
			columnVisibility: { _id: false, purchasingPrice: ['ruijun.nie@genechamp.com', 'irene.ding@genechamp.com'].includes(userInfo.email) },
			sorting: [
				{
					id: 'createdAt', //sort by age by default on page load
					desc: true,
				},
			],
		},
		mrtTheme: (theme) => ({
			baseBackgroundColor: theme.palette.background.default, //change default background color
		}),
		paginationDisplayMode: 'pages',
		muiTablePaperProps: {
			elevation: 0,
		},
		muiToolbarAlertBannerProps: isLoadingProductsError
			? {
					color: 'error',
					children: '数据加载错误',
			  }
			: undefined,
		muiTableContainerProps: {
			sx: {
				minHeight: '500px',
			},
		},
		onCreatingRowCancel: () => setValidationErrors({}),
		onCreatingRowSave: handleCreateProduct,
		onEditingRowCancel: () => setValidationErrors({}),
		onEditingRowSave: handleSaveProduct,
		renderRowActions: ({ row, table }) => (
			<>
				<Box sx={{ display: 'flex', gap: '1rem' }}>
					<Tooltip title='编辑'>
						<Button variant='falcon-primary' size='sm' onClick={() => table.setEditingRow(row)}>
							<FontAwesomeIcon icon='edit' />
						</Button>
					</Tooltip>
					<Tooltip title='删除'>
						<Button variant='falcon-primary' size='sm' onClick={() => openDeleteConfirmModal(row)}>
							<FontAwesomeIcon icon='trash-alt' />
						</Button>
					</Tooltip>
				</Box>
			</>
		),
		renderTopToolbarCustomActions: ({ table }) => (
			<>
				<Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
					<Button
						variant='primary'
						onClick={() => {
							table.setCreatingRow(true);
						}}
					>
						新增产品
					</Button>
				</Box>
			</>
		),
		renderEmptyRowsFallback: () => <p className='my-5'>无产品数据</p>,
		state: {
			isLoading: isLoadingProducts,
			isSaving: isCreatingProduct || isUpdatingProduct || isDeletingProduct,
			showAlertBanner: isLoadingProductsError,
			showProgressBars: isFetchingProducts,
		},
	});

	return (
		<>
			<Card>
				<Card.Header>
					<Card.Title className='mb-2'>
						<Row className='flex-between-center'>
							<Col>
								<nav aria-label='breadcrumb'>
									<ol className='breadcrumb'>
										<li className='breadcrumb-item'>
											<a href='/dashboard'>
												<FontAwesomeIcon icon='home' className='me-2' />
												首页
											</a>
										</li>
										<li className='breadcrumb-item active' aria-current='page'>
											产品列表
										</li>
									</ol>
								</nav>
							</Col>
							<Col xl='auto'>
								<CardDropdown>
									<div className='py-2'>
										<Dropdown.Item
											className='text-primary'
											onClick={() => {
												setShowImportDataModal(true);
											}}
										>
											<FontAwesomeIcon icon='fa-solid fa-upload' className='me-2' size='lg' />
											<span className='fw-bolder'>批量导入产品</span>
										</Dropdown.Item>
									</div>
								</CardDropdown>
							</Col>
						</Row>
					</Card.Title>
					<p className='card-subtitle text-muted mt-1'>Product Information</p>
				</Card.Header>
				<Card.Body className='p-0'>
					<MaterialReactTable table={table} />
				</Card.Body>
			</Card>
			<ProductBatchImportModal showImportDataModal={showImportDataModal} setShowImportDataModal={setShowImportDataModal} />
		</>
	);
};

export default ProductListScreen;

const validateStringRequired = (value) => {
	return !!value?.length;
};

function validateProduct(product) {
	console.log(product);
	return {
		productCode: !validateStringRequired(product.productCode) ? '必填项！' : '',
		name: !validateStringRequired(product.name) ? '必填项！' : '',
		brand: !validateStringRequired(product.brand) ? '必填项！' : '',
		category: !validateStringRequired(product.category) ? '必填项！' : '',
		supplierName: !validateStringRequired(product.supplierName) ? '必填项！' : '',
		specification: !validateStringRequired(product.specification) ? '必填项！' : '',
		minimumSellingPrice: !validateStringRequired(product.minimumSellingPrice.toString()) ? '必填项！' : '',
	};
}
