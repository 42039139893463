import React from 'react';
import EventModalMediaContent from '../../../../../components/EventModalMediaContent';
import Flex from '../../../../../components/common/Flex';

const CustomerDetail = ({ order: { customerInfo } }) => {
	return (
		<>
			{customerInfo?.staffName && (
				<EventModalMediaContent icon='align-left' heading='客户信息'>
					<Flex justifyContent=''>
						<p className='mb-0 fs-10 mt-1 fw-normal me-3'>
							联系人：<a href='#!'>{customerInfo.staffName}</a>
						</p>
						{customerInfo?.phone && (
							<p className='mb-0 fs-10 mt-1 fw-normal'>
								电话：<a href='#!'>{customerInfo.phone}</a>
							</p>
						)}
					</Flex>
				</EventModalMediaContent>
			)}
		</>
	);
};

export default CustomerDetail;
