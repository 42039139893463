import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Badge, Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Flex from '../../../../components/common/Flex';
import { useUpdateOrderApprovalByIdMutation } from '../../../../slices/approvalsApiSlice';
import { useNotifyOrderApprovedToApplicantMutation } from '../../../../slices/notificationsApiSlice';
import { useApproveOrderMutation } from '../../../../slices/ordersApiSlice';
import CustomerDetail from './common/CustomerDetail';
import OrderNotesDetail from './common/OrderNotesDetail';
import ProductListDetail from './common/ProductListDetail';
import PaymentDetail from './common/PaymentDetail';
import EventModalMediaContent from '../../../../components/EventModalMediaContent';
import OrderPaymentScheduleNode from '../../../order/OrderPaymentScheduleNode';
import OrderDevlieryStatus from '../../../order/OrderDevlieryStatus';

const ApproveModal = ({
	setIsOpenPendingModal,
	isOpenPendingModal,
	modalPendingContent,
	refetchMyApprovals,
	refetchAROrders,
	refetchAcceptingOrders,
	refetchDeliveringOrders,
	isLoading,
	isMyApprovalsOrderFetching,
}) => {
	const { isDark } = useSelector((state) => state.config);

	const [updateOrderApprovalStatus] = useUpdateOrderApprovalByIdMutation();

	const [approveOrder, { isLoading: approvingOrder, isSuccess: approveSuccess }] = useApproveOrderMutation();

	const [notifyOrderApprovedToApplicant] = useNotifyOrderApprovedToApplicantMutation();
	const [currentBtn, setCurrentBtn] = useState('');

	const handleClose = () => {
		setIsOpenPendingModal(!isOpenPendingModal);
		refetchMyApprovals();
		refetchAROrders();
		refetchAcceptingOrders();
		refetchDeliveringOrders();
	};

	const handlePass = async (e) => {
		setCurrentBtn(e.currentTarget.value);
		try {
			const approved_res = await updateOrderApprovalStatus({
				_id: approval._id,
				status: 'approved',
			}).unwrap();

			const { approver, applicant, assistant, detail, _id, status } = approved_res;

			const approve_order_res = await approveOrder({
				orderId: detail.orderOID,
				approvalResult: status,
			}).unwrap();

			// notify applicant
			await notifyOrderApprovedToApplicant({
				toUser: applicant,
				fromUser: approver,
				content: { message_text: `订单#${approve_order_res.orderId}审批通过 ✅`, detail, approvalId: _id },
			});

			// notify assistant
			await notifyOrderApprovedToApplicant({
				toUser: assistant,
				fromUser: approver,
				content: { message_text: `订单#${approve_order_res.orderId}审批通过 ✅`, detail, approvalId: _id },
			});

			toast.success('审批通过');

			handleClose();
		} catch (error) {
			toast.error(error?.data?.message || error.error);
		}
	};

	const handleUnagree = async (e) => {
		setCurrentBtn(e.currentTarget.value);
		try {
			const approved_res = await updateOrderApprovalStatus({
				_id: approval._id,
				status: 'reject',
			}).unwrap();

			const { approver, applicant, assistant, detail, _id, status } = approved_res;

			const approve_order_res = await approveOrder({
				orderId: detail.orderOID,
				approvalResult: status,
			}).unwrap();

			await notifyOrderApprovedToApplicant({
				toUser: applicant,
				fromUser: approver,
				content: { message_text: `订单#${approve_order_res.orderId}被驳回 🚫`, detail, approvalId: _id },
			});

			await notifyOrderApprovedToApplicant({
				toUser: assistant,
				fromUser: approver,
				content: { message_text: `订单#${approve_order_res.orderId}被驳回 🚫`, detail, approvalId: _id },
			});
			toast.success('申请已驳回');
			refetchMyApprovals();
			handleClose();
		} catch (error) {
			toast.error(error?.data?.message || error.error);
		}
	};
	const { orderId, customerInfo, approval, approvalStatus } = modalPendingContent;

	return (
		<Modal show={isOpenPendingModal} onHide={handleClose} contentClassName='border' centered size='xl' scrollable>
			<Modal.Header closeButton closeVariant={isDark ? 'white' : undefined} className='bg-body-tertiary px-x1 border-bottom-0'>
				<Modal.Title>
					<Flex>
						<h5 className='mb-0'>{orderId}</h5>
						{approvalStatus === 'inProcessOfApproval' && (
							<Badge bg='danger' className='ms-2 fs-10'>
								待审批
							</Badge>
						)}
					</Flex>

					{customerInfo?.customerName && (
						<p className='mb-0 fs-10 mt-1 fw-normal'>
							客户名称：<a href='#!'>{customerInfo.customerName}</a>
						</p>
					)}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className='px-x1 pb-x1 pt-1 fs-10'>
				<Row>
					<Col>
						<CustomerDetail order={modalPendingContent} />
					</Col>
					<Col sm={7}>
						<PaymentDetail order={modalPendingContent} />
					</Col>
				</Row>
				<Row>
					<Col>
						<EventModalMediaContent icon='fa-regular fa-comment-dots' heading='订单备注'>
							<OrderNotesDetail order={modalPendingContent} />
						</EventModalMediaContent>
					</Col>

					{modalPendingContent.paymentMethod === '全额支付' && (
						<Col sm={7}>
							<EventModalMediaContent icon='fa-regular fa-comment-dots' heading='物流状态'>
								{!isLoading && !isMyApprovalsOrderFetching && <OrderDevlieryStatus order={modalPendingContent} />}
							</EventModalMediaContent>
						</Col>
					)}
					{modalPendingContent.paymentMethod === '部分支付' && (
						<Col sm={7}>
							<EventModalMediaContent icon='fa-regular fa-comment-dots' heading='尾款计划'>
								{!isLoading && !isMyApprovalsOrderFetching && <OrderPaymentScheduleNode order={modalPendingContent} refetch={refetchMyApprovals} />}
							</EventModalMediaContent>
						</Col>
					)}
				</Row>

				<ProductListDetail order={modalPendingContent} />
			</Modal.Body>
			<Modal.Footer className='bg-body-tertiary px-x1 border-top-0'>
				{approvalStatus === 'inProcessOfApproval' && (
					<>
						<Button variant='falcon-success' size='sm' value='passBtn' onClick={(e) => handlePass(e)} hidden={approveSuccess}>
							{approvingOrder && currentBtn === 'passBtn' ? (
								<Spinner size='sm' />
							) : (
								<>
									<FontAwesomeIcon icon='pencil-alt' className='fs-11 me-2' />
									<span>同意</span>
								</>
							)}
						</Button>
						<Button variant='falcon-danger' size='sm' value='unPassBtn' onClick={(e) => handleUnagree(e)} hidden={approveSuccess}>
							{approvingOrder && currentBtn === 'unPassBtn' ? (
								<Spinner size='sm' />
							) : (
								<>
									<FontAwesomeIcon icon='pencil-alt' className='fs-11 me-2' />
									<span>不同意</span>
								</>
							)}
						</Button>
						<Button as={Link} to={`/approval/order/${approval?.detail.orderOID}/detail/${approval?._id}`} variant='falcon-primary' size='sm'>
							<span>查看更多细节</span>
							<FontAwesomeIcon icon='angle-right' className='fs-11 ms-1' />
						</Button>
					</>
				)}
			</Modal.Footer>
		</Modal>
	);
};

export default ApproveModal;
