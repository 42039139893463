import '@xyflow/react/dist/style.css';
import PropTypes from 'prop-types';
import { Button, Col, Dropdown, DropdownDivider, FloatingLabel, Form, InputGroup, OverlayTrigger, ProgressBar, Row, Table, Tooltip } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Message from '../../../components/Message';
import CustomerQuotaApplyModal from './modal/CustomerQuotaApplyModal';

import { useEffect, useState } from 'react';
import Flex from '../../../components/common/Flex';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CountUp from 'react-countup';
import { toast } from 'react-toastify';
import { addToScheduleNodes, moveDownScheduleNode, moveUpScheduleNode, removeScheduleNode } from '../../../slices/orderSlice';

import CardDropdown from '../../../components/common/CardDropdown';

const OrderPaymentInfo = ({ quota, balance }) => {
	const order = useSelector((state) => state.order);
	const {
		setValue,
		register,
		watch,
		formState: { errors },
	} = useFormContext();

	const dispatch = useDispatch();
	const [processNow, setProcessNow] = useState(0);
	const [processBalance, setProcessBalance] = useState(0);

	const [scheduleNode, setScheduleNode] = useState({
		title: '',
		paymentDays: 0,
		paymentPercent: 0,
		paymentAmount: 0,
	});

	const { customer, totalPrice, scheduleNodes } = order;
	const { prePaymentOption, paymentMethod, prePaymentPercent, percentPayment } = watch();

	useEffect(() => {
		let totalPercent = 0;
		for (const node of scheduleNodes) {
			totalPercent = totalPercent + node.paymentPercent;
		}
		setProcessNow(totalPercent + prePaymentPercent);
		setProcessBalance(100 - (totalPercent + prePaymentPercent));
	}, [dispatch, percentPayment, prePaymentPercent, scheduleNode, scheduleNodes]);

	return (
		<>
			{paymentMethod === '全额支付' && <Message variant='success'>全额支付: ¥ {order.totalPrice} </Message>}
			{paymentMethod === '部分支付' && (
				<>
					<p>预付信息</p>
					<Form.Check
						inline
						type='radio'
						id='defaultRadio'
						label='百分比'
						name='prePaymentRadio'
						value='百分比'
						{...register('prePaymentOption', {
							onChange: (e) => {
								setValue('prePaymentOption', e.target.value);
							},
						})}
					/>
					<Form.Check
						inline
						type='radio'
						id='checkedRadio'
						label='预付款(调试中)'
						name='prePaymentRadio'
						value='预付款'
						{...register('prePaymentOption', { onChange: (e) => setValue('prePaymentOption', e.target.value) })}
						disabled
					/>
					{prePaymentOption === '百分比' && (
						<>
							<Form.Group as={Row} className='mb-3' controlId='prePaymentPercent'>
								<Form.Label column sm='2'>
									百分比<span className='text-danger'>*</span>
								</Form.Label>
								<Col sm='6'>
									<InputGroup>
										<Form.Control
											type='number'
											name='prePaymentPercent'
											autoComplete='false'
											isInvalid={!!errors.prePaymentPercent}
											{...register('prePaymentPercent', {
												valueAsNumber: true,
												onChange: (e) => {
													//dispatch(clearScheduleNodes());
													if (Number(e.target.value) === 100) {
														setValue('paymentMethod', '全额支付');
														setValue('prePaymentPercent', 0);
														setValue('percentPayment', 0);
														setValue('percentBalance', totalPrice);
													} else {
														const calculatedPercentPayment = (totalPrice * (e.target.value / 100)).toFixed(2);
														const calculatedPercentBalance = (totalPrice - calculatedPercentPayment).toFixed(2);
														setValue('prePaymentPercent', Number(e.target.value));
														setValue('percentPayment', calculatedPercentPayment >= 0 ? calculatedPercentPayment : 0);
														setValue('percentBalance', calculatedPercentBalance >= 0 ? calculatedPercentBalance : totalPrice);
													}
												},
											})}
										/>
										<InputGroup.Text id='basic-addon2'>%</InputGroup.Text>
										<Form.Control.Feedback type='invalid'>{errors.prePaymentPercent?.message}</Form.Control.Feedback>
									</InputGroup>
								</Col>
							</Form.Group>
							<Form.Group as={Row} className='align-items-center mb-3'>
								<Form.Label column sm='2'>
									首次支付
								</Form.Label>
								<Col sm='6'>
									<InputGroup>
										<Form.Control
											disabled
											type='text'
											name='percentPayment'
											{...register('percentPayment', {
												valueAsNumber: true,
											})}
										/>
										<InputGroup.Text id='basic-addon2'>元</InputGroup.Text>
									</InputGroup>
								</Col>
							</Form.Group>
							<Form.Group as={Row} className='align-items-center mb-3'>
								<Form.Label column sm='2'>
									尾款剩余
								</Form.Label>
								<Col sm='6'>
									<InputGroup>
										<Form.Control disabled type='text' name='percentBalance' {...register('percentBalance', { valueAsNumber: true })} />
										<InputGroup.Text id='basic-addon2'>元</InputGroup.Text>
									</InputGroup>
								</Col>
							</Form.Group>
							<Row className='my-4'>
								<Col sm='8'>
									<OverlayTrigger
										overlay={
											<Tooltip style={{ position: 'fixed' }} id='overlay-trigger-example'>
												已分配{Number(processNow)}% / 剩余{100 - Number(processNow)}%
											</Tooltip>
										}
									>
										<ProgressBar className='rounded-0' variant='primary' now={Number(processNow)} label={`${Number(processNow)}%`} key={2} />
									</OverlayTrigger>
								</Col>
								<Col>
									{processBalance === 0 ? (
										<span>
											<FontAwesomeIcon icon='check' className='text-success' />
										</span>
									) : (
										<span>
											<FontAwesomeIcon icon='fa-solid fa-circle-exclamation' className='me-1' />
											剩余百分比
											<strong>
												<CountUp end={processBalance} duration={1} suffix='%' className='ms-1' />
											</strong>
										</span>
									)}
								</Col>
							</Row>
							<Table className='gx-2 flex-between-center mb-3'>
								<thead>
									<tr>
										<th>付款节点</th>
										<th>账期(天)</th>
										<th>付款百分比</th>
										<th>付款金额</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{scheduleNodes.map((scheduleNode, index) => (
										<tr key={index}>
											<td>
												<span>{scheduleNode.title}</span>
											</td>
											<td className='mb-0 text-700'>{scheduleNode.paymentDays}天</td>
											<td className='mb-0 text-700'>{scheduleNode.paymentPercent}%</td>
											<td className='mb-0 text-700'>¥{scheduleNode.paymentAmount}</td>
											<td>
												<Flex justifyContent='end'>
													<CardDropdown drop='start'>
														<div className='py-2'>
															{index !== 0 && (
																<Dropdown.Item
																	className='text-primary'
																	onClick={() => {
																		dispatch(moveUpScheduleNode({ fromIndex: index, toIndex: index - 1 }));
																	}}
																>
																	<FontAwesomeIcon className='fs-10 me-2' icon='fa-angle-up' />
																	上移
																</Dropdown.Item>
															)}
															{index !== scheduleNodes.length - 1 && (
																<Dropdown.Item
																	className='text-primary'
																	onClick={() => {
																		dispatch(moveDownScheduleNode({ fromIndex: index, toIndex: index + 1 }));
																	}}
																>
																	<FontAwesomeIcon className='fs-10 me-2' icon='fa-angle-down' />
																	下移
																</Dropdown.Item>
															)}
															<DropdownDivider />
															<Dropdown.Item
																className='text-danger'
																onClick={() => {
																	dispatch(removeScheduleNode(index));
																	setProcessNow(Number(processNow) - Number(scheduleNode.paymentPercent));
																	setScheduleNode({
																		title: '',
																		paymentDays: 0,
																		paymentPercent: 0,
																		paymentAmount: 0,
																	});
																}}
															>
																<FontAwesomeIcon className='fs-10 me-2' icon='trash-alt' />
																删除
															</Dropdown.Item>
														</div>
													</CardDropdown>
												</Flex>
											</td>
										</tr>
									))}
								</tbody>
							</Table>
							<Row className='gy-3 gx-2 mb-4' hidden={processNow === 100}>
								<Col sm={3}>
									<Form.Select
										size='sm'
										name='scheduleNodeTitle'
										placeholder='节点名称'
										value={scheduleNode.title}
										onChange={(e) => {
											setScheduleNode({ ...scheduleNode, title: e.target.value });
										}}
									>
										<option value=''>请选择付款节点</option>
										<option value='发货/提供服务'>发货/提供服务</option>
										<option value='验收'>验收</option>
									</Form.Select>
								</Col>
								<Col sm={9}>
									<Flex justifyContent='between' alignItems='center' className='gap-2'>
										<Form.Control
											type='Number'
											size='sm'
											name='scheduleNodePaymentDays'
											placeholder='账期'
											value={scheduleNode.paymentDays}
											hidden={processNow === 100}
											onChange={(e) =>
												setScheduleNode({
													...scheduleNode,
													paymentDays: Number.parseInt(e.target.value),
												})
											}
										/>
										<Form.Control
											type='number'
											size='sm'
											name='scheduleNodePaymentPercent'
											placeholder='付款百分比'
											value={scheduleNode.paymentPercent}
											max={10}
											min={0}
											onChange={(e) => {
												let value;
												if (e.target.value > processBalance) {
													value = processBalance;
													toast.info(`设置的百分比应在${processBalance}%以内`, { position: 'top-center' });
												} else {
													value = e.target.value;
												}

												setScheduleNode({
													...scheduleNode,
													paymentPercent: Number.parseInt(value),
													paymentAmount: Number.parseFloat((Number(value / 100) * Number(totalPrice)).toFixed(2)),
												});
											}}
										/>
										<Form.Control type='text' size='sm' name='scheduleNodePaymentAmount' placeholder='待计算付款金额' disabled={true} value={scheduleNode.paymentAmount} />
										<Button
											variant='falcon-default'
											size='sm'
											className='me-2'
											type='button'
											disabled={scheduleNode.title === '' || scheduleNode.paymentDays === 0 || processNow === 100}
											onClick={() => {
												dispatch(
													addToScheduleNodes({
														icon: scheduleNode.title === '发货/提供服务' ? 'fa-truck-fast' : scheduleNode.title === '验收' ? 'fa-box-open' : '',
														title: scheduleNode.title,
														paymentDays: scheduleNode.paymentDays,
														paymentPercent: scheduleNode.paymentPercent,
														paymentAmount: scheduleNode.paymentAmount,
													})
												);

												setProcessNow(Number(processNow) + Number(scheduleNode.paymentPercent));

												setScheduleNode({
													title: '',
													paymentDays: 0,
													paymentPercent: 0,
													paymentAmount: 0,
												});
											}}
										>
											<FontAwesomeIcon icon='plus' />
										</Button>
									</Flex>
								</Col>
							</Row>
						</>
					)}

					{prePaymentOption === '预付款' && (
						<>
							<Form.Group as={Row} className='g-1 mb-3' controlId='prePayment'>
								<Form.Label column sm='2'>
									预付款<span className='text-danger'>*</span>
								</Form.Label>
								<Col sm='6'>
									<InputGroup>
										<Form.Control
											type='number'
											name='prePayment'
											placeholder='请填写预付款项'
											autoComplete='false'
											isInvalid={!!errors.prePayment}
											{...register('prePayment', {
												valueAsNumber: true,
												onChange: (e) => {
													const calculatedPrePaymentBalance = (totalPrice - e.target.value).toFixed(2);
													setValue('prePaymentBalance', calculatedPrePaymentBalance >= 0 ? calculatedPrePaymentBalance : 0);
												},
											})}
										/>
										<InputGroup.Text id='basic-addon2'>元</InputGroup.Text>
										<Form.Control.Feedback type='invalid'>{errors.prePayment?.message}</Form.Control.Feedback>
									</InputGroup>
								</Col>
							</Form.Group>
							<Form.Group as={Row} className='g-1 align-items-center mb-3'>
								<Form.Group as={Row} className='g-1 align-items-center mb-3'>
									<Form.Label column sm='2'>
										尾款剩余
									</Form.Label>
									<Col sm='6'>
										<InputGroup>
											<Form.Control disabled type='text' name='prePaymentBalance' {...register('prePaymentBalance')} />
											<InputGroup.Text id='basic-addon2'>元</InputGroup.Text>
										</InputGroup>
									</Col>
								</Form.Group>
							</Form.Group>
						</>
					)}

					<Form.Group className='mb-3' controlId='paymentExplain'>
						<Form.Label>付款说明</Form.Label>
						<FloatingLabel label='在此描述付款协商内容......'>
							<Form.Control as='textarea' name='paymentExplain' rows={3} style={{ height: '100px' }} isInvalid={!!errors.paymentExplain} {...register('paymentExplain')} />
							<Form.Control.Feedback type='invalid'>{errors.paymentExplain?.message}</Form.Control.Feedback>
						</FloatingLabel>
					</Form.Group>
				</>
			)}
			{paymentMethod === '额度支付' && quota === 0 && (
				<Message variant='warning'>
					<p>
						客户: {customer.name}
						<strong>无可用额度</strong>
					</p>
					<p>
						<CustomerQuotaApplyModal />
					</p>
				</Message>
			)}
			{paymentMethod === '额度支付' && quota !== 0 && balance < totalPrice && (
				<>
					<Message variant='success'>
						当前选择的客户 {order.customer.name} 总额度为 ¥ {quota} /可用额度为 ¥ <strong>{balance}</strong>
					</Message>
					<Message variant='danger'>
						<p>当前订单需支付: {totalPrice} 元</p>
						<strong>
							可用余额不足! <Link>去提额</Link>
						</strong>
					</Message>
				</>
			)}
			{paymentMethod === '额度支付' && quota !== 0 && balance > totalPrice && (
				<>
					<Message variant='success'>
						当前选择的客户 {order.customer.name} 总额度为 ¥ {quota} /可用额度为 ¥ <strong>{balance}</strong>
					</Message>
					<p>当前订单需支付: {totalPrice} 元</p>
				</>
			)}
		</>
	);
};

OrderPaymentInfo.propTypes = {
	paymentMethod: PropTypes.string,
};

export default OrderPaymentInfo;
