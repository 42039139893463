import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Badge, Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Flex from '../../../../components/common/Flex';
import Message from '../../../../components/Message';
import { useGetOrderDetailsQuery, usePayOrdersMutation } from '../../../../slices/ordersApiSlice';
import CustomerDetail from './common/CustomerDetail';
import ModalLoading from './common/ModalLoading';
import OrderNotesDetail from './common/OrderNotesDetail';
import ProductListDetail from './common/ProductListDetail';
import PaymentDetail from './common/PaymentDetail';
import EventModalMediaContent from '../../../../components/EventModalMediaContent';
import OrderPaymentScheduleNode from '../../../order/OrderPaymentScheduleNode';
import OrderDevlieryStatus from '../../../order/OrderDevlieryStatus';

const PrePaymentModal = ({ setIsOpenPendingModal, isOpenPendingModal, setModalPendingContent, refetchPrePaymentOrders, refetchAROrders, isPrePaymentOrderFetching, orderOId }) => {
	const { isDark } = useSelector((state) => state.config);
	const { userInfo } = useSelector((state) => state.auth);

	const { data: order = [], refetch, isLoading, isFetching: isOrderFetching } = useGetOrderDetailsQuery(orderOId, { skip: !isOpenPendingModal });

	const [payOrder, { isLoading: loadingPay }] = usePayOrdersMutation();

	const handleClose = () => {
		setIsOpenPendingModal(!isOpenPendingModal);
		setModalPendingContent({});
		refetchAROrders();
	};

	const onPayOrder = async (accountsReceivable, title, orderOId, orderId) => {
		await payOrder({ orderOId, details: { payAccountsReceivable: accountsReceivable, title } });
		refetchPrePaymentOrders();
		toast.success(orderId + '订单' + title + '款项已核销!');
		handleClose();
	};

	const { _id, orderId, customerInfo, approvalStatus, paymentStatus } = order;

	return (
		<Modal show={isOpenPendingModal} onHide={handleClose} contentClassName='border' centered size='xl' scrollable>
			<Modal.Header closeButton closeVariant={isDark ? 'white' : undefined} className='bg-body-tertiary px-x1 border-bottom-0'>
				<Modal.Title>
					<Flex>
						<h5 className='mb-0'>{orderId}</h5>
						{approvalStatus === 'inProcessOfApproval' && (
							<Badge bg='danger' className='ms-2 fs-10'>
								待审批
							</Badge>
						)}
						{['approved', 'systemApproved'].includes(approvalStatus) && paymentStatus === 'waitingPayPlaceOrder' && (
							<Badge bg='warning' className='ms-2 fs-10'>
								待收预付款
							</Badge>
						)}
						{['approved', 'systemApproved'].includes(approvalStatus) && paymentStatus === 'waitingPayDelivery' && (
							<Badge bg='warning' className='ms-2 fs-10'>
								待收发货款
							</Badge>
						)}
						{['approved', 'systemApproved'].includes(approvalStatus) && paymentStatus === 'waitingPayAccept' && (
							<Badge bg='warning' className='ms-2 fs-10'>
								待收验收款
							</Badge>
						)}
					</Flex>

					{customerInfo?.customerName && (
						<p className='mb-0 fs-10 mt-1 fw-normal'>
							客户名称：<a href='#!'>{customerInfo.customerName}</a>
						</p>
					)}
					{isPrePaymentOrderFetching ? (
						<Spinner className='text-info' />
					) : (
						order?.accountsReceivables?.length !== 0 &&
						order?.accountsReceivables?.map((ar) => (
							<>
								<p className='mb-0 fs-10 mt-1 fw-normal'>
									订单{ar.title}应收账款 共计 {ar.accountsReceivable} 元
								</p>
							</>
						))
					)}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className='px-x1 pb-x1 pt-1 fs-10'>
				{isLoading || isOrderFetching ? (
					<ModalLoading />
				) : (
					<>
						<Row>
							<Col>
								<CustomerDetail order={order} />
							</Col>
							<Col sm={7}>
								<PaymentDetail order={order} />
								{isPrePaymentOrderFetching && <Message>正在更新订单...</Message>}
							</Col>
						</Row>
						<Row>
							<Col>
								<EventModalMediaContent icon='fa-regular fa-comment-dots' heading='订单备注'>
									<OrderNotesDetail order={order} />
								</EventModalMediaContent>
							</Col>
							{order.paymentMethod === '全额支付' && (
								<Col sm={7}>
									<EventModalMediaContent icon='fa-regular fa-comment-dots' heading='物流状态'>
										{!isLoading && !isOrderFetching && <OrderDevlieryStatus order={order} />}
									</EventModalMediaContent>
								</Col>
							)}
							{order.paymentMethod === '部分支付' && (
								<Col sm={7}>
									<EventModalMediaContent icon='fa-regular fa-comment-dots' heading='尾款计划'>
										{!isLoading && !isOrderFetching && <OrderPaymentScheduleNode order={order} refetch={refetch} />}
									</EventModalMediaContent>
								</Col>
							)}
						</Row>
						<ProductListDetail order={order} />
					</>
				)}
			</Modal.Body>

			{userInfo.role === 'finance' && (
				<Modal.Footer className='bg-body-tertiary px-x1 border-top-0'>
					<>
						{isPrePaymentOrderFetching ? (
							<Spinner className='text-info' />
						) : (
							order?.accountsReceivables?.map((ar) => {
								if (!ar.isPaid) {
									return (
										<p className='mb-0 fs-10 mt-1 fw-normal'>
											订单{ar.title}应收账款 共计 {ar.accountsReceivable} 元
										</p>
									);
								} else {
									return <></>;
								}
							})
						)}
						{order?.accountsReceivables?.map((ar) => {
							if (!ar.isPaid) {
								return (
									<Button size='sm' onClick={() => onPayOrder(ar.accountsReceivable, ar.title, order._id, order.orderId)}>
										<FontAwesomeIcon icon='fa-solid fa-stamp' className='me-2' />
										确认已收{ar.title}款 {loadingPay && <Spinner size='sm'></Spinner>}
									</Button>
								);
							} else {
								return <></>;
							}
						})}

						<Button as={Link} to={`/order/detail/${_id}`} variant='falcon-primary' size='sm'>
							<span>查看更多细节</span>
							<FontAwesomeIcon icon='angle-right' className='fs-11 ms-1' />
						</Button>
					</>
				</Modal.Footer>
			)}
		</Modal>
	);
};

export default PrePaymentModal;
