import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import timeZone from 'moment-timezone';
import 'moment/locale/zh-cn';
import moment from 'moment/moment.js';
import React from 'react';
import { Badge, Col, Row } from 'react-bootstrap';
import SubtleBadge from '../../../../../components/common/SubtleBadge';
import EventModalMediaContent from '../../../../../components/EventModalMediaContent';

const PaymentDetail = ({
	order: { _id, orderId, accountsReceivables, scheduleNodes, totalPrice, paymentMethod, percentPayment, prePaymentPercent, prePaymentOption },
	refetch,
}) => {
	return (
		<>
			<Row>
				<Col>{totalPrice && <EventModalMediaContent icon='fa-regular fa-comment-dots' heading='订单总价' content={`¥${totalPrice}`} />}</Col>

				<Col>{paymentMethod && <EventModalMediaContent icon='fa-regular fa-comment-dots' heading='支付方式' content={`${paymentMethod}`} />}</Col>
			</Row>

			{paymentMethod === '全额支付' && (
				<>
					<Row className='mb-3'>
						<EventModalMediaContent icon='clock' heading='支付金额'>
							<span className='me-3'>¥ {accountsReceivables.filter((a) => a.title === '预付')[0].accountsReceivable}</span>
							<SubtleBadge className='me-2'>{accountsReceivables.filter((a) => a.title === '预付')[0].paymentPercent} %</SubtleBadge>
							<span>
								{accountsReceivables.filter((a) => a.title === '预付')[0] && accountsReceivables.filter((a) => a.title === '预付')[0].isPaid ? (
									<Badge className='me-3' bg='success'>
										已收款
									</Badge>
								) : (
									<Badge className='me-3' bg='warning'>
										待确认
									</Badge>
								)}
								{accountsReceivables.filter((a) => a.title === '预付')[0] && accountsReceivables.filter((a) => a.title === '预付')[0].isPaid && (
									<>
										<FontAwesomeIcon icon='check' className='text-success me-2' />
										<span className='text-success-emphasis fw-semibold fs-11 '>
											{accountsReceivables.filter((a) => a.title === '预付')[0].title}款已支付在{' '}
											{timeZone.tz(moment(accountsReceivables.filter((a) => a.title === '预付')[0].paidAt), 'Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss')}
										</span>
									</>
								)}
							</span>
						</EventModalMediaContent>
					</Row>
				</>
			)}
			{paymentMethod === '部分支付' && prePaymentOption === '百分比' && (
				<>
					{percentPayment !== 0 && accountsReceivables?.filter((a) => a.title === '预付')[0] ? (
						<EventModalMediaContent icon='fa-regular fa-comment-dots' heading='预付金额'>
							<span className='me-3'>¥{percentPayment}</span>
							<SubtleBadge className='me-2'>{prePaymentPercent}%</SubtleBadge>

							<span>
								{accountsReceivables.filter((a) => a.title === '预付')[0].isPaid ? (
									<Badge className='me-3' bg='success'>
										已收款
									</Badge>
								) : (
									<Badge className='me-3' bg='warning'>
										待确认
									</Badge>
								)}
								{accountsReceivables.filter((a) => a.title === '预付')[0].isPaid && (
									<>
										<FontAwesomeIcon icon='check' className='text-success me-2' />
										<span className='text-success-emphasis fs-11 '>
											{accountsReceivables.filter((a) => a.title === '预付')[0].title}款已支付在{' '}
											{timeZone.tz(moment(accountsReceivables.filter((a) => a.title === '预付')[0].paidAt), 'Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss')}
										</span>
									</>
								)}
							</span>
						</EventModalMediaContent>
					) : (
						<EventModalMediaContent icon='fa-regular fa-comment-dots' heading='预付金额'>
							<FontAwesomeIcon icon='check' className='text-success me-2' />
							<span className='text-success-emphasis fs-11 '>订单无需支付预付款</span>
						</EventModalMediaContent>
					)}
				</>
			)}
		</>
	);
};

export default PaymentDetail;
