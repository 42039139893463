import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import timeZone from 'moment-timezone';
import 'moment/locale/zh-cn';
import moment from 'moment/moment.js';
import React from 'react';
import { Badge } from 'react-bootstrap';

const OrderNotesDetail = ({ order: { orderNotes } }) => {
	return (
		<>
			{orderNotes &&
				orderNotes.map((item, index) => (
					<div key={index}>
						<span>
							<FontAwesomeIcon icon='fa-circle-user' className='text-primary me-1' />
							<span className='me-3'>{item.user_name}</span>
							<FontAwesomeIcon icon='fa-solid fa-quote-left' className='text-primary' />
							<span className='mx-2'>{item.note}</span>
							<FontAwesomeIcon icon='fa-solid fa-quote-right' className='text-primary' />
						</span>
						{item.type === 'create' && <Badge className='mx-3'>新建</Badge>}
						{item.type === 'pay' && <Badge className='mx-3'>财务</Badge>}
						{item.type === 'delivery' && <Badge className='mx-3'>发货</Badge>}
						{item.type === 'accept' && <Badge className='mx-3'>验收</Badge>}
						{item.type === 'additional' && <Badge className='mx-3'>其他</Badge>}
						<span className='fs-11'>{timeZone.tz(moment(item?.create_time).locale('zh-cn'), 'Asia/Shanghai').format('lll')}</span>
					</div>
				))}
		</>
	);
};

export default OrderNotesDetail;
