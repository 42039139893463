import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import moment from 'moment';
import React, { useMemo } from 'react';
import { Badge, Card, Col, Dropdown, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AdvanceTable from '../../../components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from '../../../components/common/advance-table/AdvanceTableFooter';
import CardDropdown from '../../../components/common/CardDropdown';
import SubtleBadge from '../../../components/common/SubtleBadge';
import Loader from '../../../components/Loader';
import Message from '../../../components/Message';
import useAdvanceTable from '../../../hooks/useAdvanceTable';
import AdvanceTableProvider from '../../../providers/AdvanceTableProvider';
import { useOrderContext } from '../../../providers/OrderProvider';
import AdvanceTableSearchBox from '../../../components/common/advance-table/AdvanceTableSearchBox';

const RecentOrderPendings = () => {
	const { userInfo } = useSelector((state) => state.auth);

	const {
		orderPendingForManager,
		orderPendingForFinance,
		orderPendingForBuyer,
		orderPendingForAssistant,
		orderPendingForSale,
		loadingManagerPending,
		loadingFinancePending,
		loadingBuyerPending,
		loadingAssistantPending,
		loadingSalePending,
		loadingManagerPendingError,
		loadingAssistantPendingError,
		loadingFinancePendingError,
		loadingBuyerPendingError,
		loadingSalePendingError,
		uninitializedAssistant,
		uninitializedBuyer,
		uninitializedFinance,
		uninitializedManager,
		uninitializedSale,
	} = useOrderContext();

	const columns = useMemo(() => {
		const userColumns = [
			{
				accessorKey: 'orderId',
				header: '订单编号',
				meta: {
					headerProps: { className: 'sticky-column text-900' },
					cellProps: {
						className: 'py-2 white-space-nowrap pe-3 pe-xxl-4 ps-2',
					},
				},
				cell: ({ row: { original } }) => {
					const { orderId, _id } = original;

					return (
						<>
							<div className='d-flex justify-content-center align-items-center'>
								<OverlayTrigger
									overlay={
										<Tooltip style={{ position: 'fixed' }} id='basePriceTooltip'>
											<FontAwesomeIcon icon='fa-spinner' className={classNames('me-2', `text-info`)} />
											订单进行中
										</Tooltip>
									}
								>
									<FontAwesomeIcon icon='fa-spinner' className={classNames('me-2', `text-info`)} />
								</OverlayTrigger>

								<Link to={`/order/detail/${_id}`} className='text-info'>
									{orderId}
								</Link>
							</div>
						</>
					);
				},
			},
			{
				accessorKey: 'customerInfo.customerName',
				header: '客户名称',
				meta: {
					headerProps: { className: 'pe-3 text-900 text-start' },
					cellProps: { className: 'text-start' },
				},
				cell: ({ row: { original } }) => {
					const { customerInfo } = original;
					return <span className='text-info'>{customerInfo.customerName}</span>;
				},
			},
			{
				accessorKey: 'deliveryStatus',
				header: '物流状态',
				meta: {
					headerProps: { className: 'text-center text-900' },
				},
				cell: ({ row: { original } }) => {
					const { deliveryStatus } = original;
					let bg = '';
					let icon = '';
					let text = '';
					switch (deliveryStatus) {
						case 'waitingDelivery':
							bg = 'warning';
							icon = 'stream';
							text = '待发货';
							break;
						case 'waitingAccept':
							bg = 'info';
							icon = 'stream';
							text = '待验收';
							break;
						case 'waitingComplete':
							bg = 'info';
							icon = 'stream';
							text = '待完成';
							break;
						case 'deliveryComplete':
							bg = 'success';
							icon = 'check';
							text = '物流完成';
							break;
						default:
							break;
					}
					return (
						<SubtleBadge pill={deliveryStatus === 'deliveryComplete'} bg={bg} className='me-2'>
							<FontAwesomeIcon icon={icon} className='me-2' />
							<span className='fs-11'>{text}</span>
						</SubtleBadge>
					);
				},
			},
			{
				accessorKey: 'saleUser.name',
				header: '销售员',
				meta: {
					headerProps: { className: 'text-center text-900' },
				},
				cell: ({ row: { original } }) => {
					const { saleUser } = original;
					return <span className='fs-11 fw-normal'>{saleUser.name}</span>;
				},
			},
			{
				accessorKey: 'assistantUser.name',
				header: '销售助理',
				meta: {
					headerProps: { className: 'text-center text-900' },
				},
				cell: ({ row: { original } }) => {
					const { assistantUser } = original;
					return <span className='fs-11 fw-normal'>{assistantUser?.name}</span>;
				},
			},
			{
				accessorKey: 'totalPrice',
				header: '总价',
				meta: {
					headerProps: {
						className: 'text-end text-900',
					},
					cellProps: {
						className: 'text-end',
					},
				},
				cell: ({ row: { original } }) => {
					return <span>¥{original.totalPrice}</span>;
				},
			},
			{
				accessorKey: 'createdAt',
				header: '创建时间',
				meta: {
					headerProps: {
						className: 'text-end text-900',
					},
					cellProps: {
						className: 'text-end',
					},
				},
				cell: ({ row: { original } }) => {
					return <span>{moment(original.createdAt).format('YYYY-MM-DD HH:mm')}</span>;
				},
			},
			{
				accessorKey: 'none',
				header: '',
				enableSorting: false,
				meta: {
					cellProps: {
						className: 'text-end py-2',
					},
				},
				cell: ({ row: { original } }) => {
					return (
						<CardDropdown drop='start'>
							<div className='py-2'>
								<Dropdown.Item href={`/order/detail/${original?._id}`}>查看</Dropdown.Item>
							</div>
						</CardDropdown>
					);
				},
			},
		];
		if (userInfo.role === 'manager') {
			const b = [
				{
					accessorKey: 'saleUser.name',
					header: '销售员',
					meta: {
						headerProps: { className: 'text-center text-900' },
					},
					cell: ({ row: { original } }) => {
						const { saleUser } = original;
						return <span className='fs-11 fw-normal'>{saleUser.name}</span>;
					},
				},
				{
					accessorKey: 'assistantUser.name',
					header: '销售助理',
					meta: {
						headerProps: { className: 'text-center text-900' },
					},
					cell: ({ row: { original } }) => {
						const { assistantUser } = original;
						return <span className='fs-11 fw-normal'>{assistantUser?.name}</span>;
					},
				},
			];
			userColumns.splice(1, 1, ...b);
		}
		if (['sale', 'user'].includes(userInfo.role)) {
			const x = [
				{
					accessorKey: 'assistantUser.name',
					header: '销售助理',
					meta: {
						headerProps: { className: 'text-center text-900' },
					},
					cell: ({ row: { original } }) => {
						const { assistantUser } = original;
						return <span className='fs-11 fw-normal'>{assistantUser?.name}</span>;
					},
				},
				{
					accessorKey: 'orderTask',
					header: '订单任务',
					meta: {
						headerProps: { className: 'text-center text-900' },
					},
					cell: ({ row: { original } }) => {
						const { orderTask, _id } = original;
						return (
							<Badge bg='danger' className='me-2'>
								<FontAwesomeIcon icon='fa-solid fa-bolt' className='me-2' />
								<span className='fs-11'>
									<Link to={`/order/detail/${_id}`} className='text-white'>
										{orderTask}
									</Link>
								</span>
							</Badge>
						);
					},
				},
			];
			userColumns.splice(1, 1, ...x);
		}
		if (userInfo.role === 'assistant') {
			const a = [
				{
					accessorKey: 'saleUser.name',
					header: '销售员',
					meta: {
						headerProps: { className: 'text-center text-900' },
					},
					cell: ({ row: { original } }) => {
						const { saleUser } = original;
						return <span className='fs-11 fw-normal'>{saleUser.name}</span>;
					},
				},
				{
					accessorKey: 'assistantUser.name',
					header: '销售助理',
					meta: {
						headerProps: { className: 'text-center text-900' },
					},
					cell: ({ row: { original } }) => {
						const { assistantUser } = original;
						return <span className='fs-11 fw-normal'>{assistantUser?.name}</span>;
					},
				},
				{
					accessorKey: 'orderTask',
					header: '订单任务',
					meta: {
						headerProps: { className: 'text-center text-900' },
					},
					cell: ({ row: { original } }) => {
						const { orderTask } = original;
						return (
							<Badge bg='danger' className='me-2'>
								<FontAwesomeIcon icon='fa-solid fa-bolt' className='me-2' />
								<span className='fs-11'>{orderTask}</span>
							</Badge>
						);
					},
				},
			];
			userColumns.splice(1, 1, ...a);
		}
		if (userInfo.role === 'buyer') {
			const buyerColumns = [
				{
					accessorKey: 'customerInfo',
					header: '客户名称',
					meta: {
						headerProps: { className: 'pe-3 text-900 text-start' },
						cellProps: { className: 'text-start' },
					},
					cell: ({ row: { original } }) => {
						const { customerInfo } = original;
						return <span className='text-info'>{customerInfo.customerName}</span>;
					},
				},
				{
					accessorKey: 'paymentStatus',
					header: '支付状态',
					meta: {
						headerProps: { className: 'text-900' },
					},
					cell: ({ row: { original } }) => {
						const { paymentStatus, isCompleted } = original;

						let bg = '';
						let icon = '';
						let text = '';
						switch (paymentStatus) {
							case 'waitingPayPlaceOrder':
								bg = 'warning';
								icon = 'stream';
								text = '待收预付款';
								break;
							case 'waitingPayDelivery':
								bg = 'warning';
								icon = 'stream';
								text = '待收发货尾款';
								break;
							case 'waitingPayAccept':
								bg = 'warning';
								icon = 'stream';
								text = '待收验收尾款';
								break;
							case 'waitingPayComplete':
								bg = 'warning';
								icon = 'stream';
								text = '待支付完成';
								break;
							case 'payComplete':
								bg = 'success';
								icon = 'check';
								text = '支付完成';
								break;
							default:
								break;
						}

						if (!isCompleted && paymentStatus === 'payComplete') {
							return (
								<Badge bg='success' className='me-2'>
									<FontAwesomeIcon icon='success' className='me-2' />
									<span className='fs-11'>当前无应收</span>
								</Badge>
							);
						} else {
							return (
								<SubtleBadge pill={paymentStatus === 'payComplete'} bg={bg} className='me-2'>
									<FontAwesomeIcon icon={icon} className='me-2' />
									<span className='fs-11'>{text}</span>
								</SubtleBadge>
							);
						}
					},
				},
			];
			userColumns.splice(1, 1, ...buyerColumns);
			return userColumns.filter((column) => column.accessorKey !== 'currentAccountsReceivable');
		}
		return userColumns;
	}, [userInfo.role]);

	const table = useAdvanceTable({
		data:
			userInfo.role === 'buyer'
				? orderPendingForBuyer
				: ['sale', 'user'].includes(userInfo.role)
				? orderPendingForSale
				: userInfo.role === 'assistant'
				? orderPendingForAssistant
				: userInfo.role === 'manager'
				? orderPendingForManager
				: orderPendingForFinance,
		columns: columns,
		sortable: true,
		pagination: true,
		perPage: 7,
		initialState: {
			sorting: [{ id: 'createdAt', desc: true }],
		},
	});

	return (
		<AdvanceTableProvider {...table}>
			<Row className='flex-center my-3'>
				<Col xs='auto' sm={6} lg={4}>
					<AdvanceTableSearchBox />
				</Col>
			</Row>
			<AdvanceTable
				headerClassName='bg-200 text-nowrap align-middle font-sans-serif'
				rowClassName='btn-reveal-trigger text-nowrap align-middle'
				tableProps={{
					className: 'fs-10 fw-semibold mb-0 overflow-hidden',
				}}
			/>
			{!uninitializedManager && !loadingManagerPending && orderPendingForManager.length === 0 && (
				<div className='my-5 w-100 text-center'>
					<p className=''>暂无订单</p>
				</div>
			)}
			{!uninitializedFinance && !loadingFinancePending && orderPendingForFinance.length === 0 && (
				<div className='my-5 w-100 text-center'>
					<p className=''>暂无待处理订单</p>
				</div>
			)}
			{!uninitializedBuyer && !loadingBuyerPending && orderPendingForBuyer.length === 0 && (
				<div className='my-5 w-100 text-center'>
					<p className=''>暂无待处理订单</p>
				</div>
			)}
			{!uninitializedSale && !loadingSalePending && orderPendingForSale.length === 0 && (
				<div className='my-5 w-100 text-center'>
					<p className=''>暂无待处理订单</p>
				</div>
			)}
			{!uninitializedAssistant && !loadingAssistantPending && orderPendingForAssistant.length === 0 && (
				<div className='my-5 w-100 text-center'>
					<p className=''>暂无待处理订单</p>
				</div>
			)}
			{loadingManagerPending && <Loader />}
			{loadingFinancePending && <Loader />}
			{loadingBuyerPending && <Loader />}
			{loadingSalePending && <Loader />}
			{loadingAssistantPending && <Loader />}
			{loadingManagerPendingError && <Message animation='border' variant='primary' className='mt-3' />}
			{loadingFinancePendingError && <Message animation='border' variant='primary' className='mt-3' />}
			{loadingBuyerPendingError && <Message animation='border' variant='primary' className='mt-3' />}
			{loadingSalePendingError && <Message animation='border' variant='primary' className='mt-3' />}
			{loadingAssistantPendingError && <Message animation='border' variant='primary' className='mt-3' />}

			<Card.Footer>
				<AdvanceTableFooter rowInfo navButtons />
			</Card.Footer>
		</AdvanceTableProvider>
	);
};

export default RecentOrderPendings;
