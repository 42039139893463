import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import timeZone from 'moment-timezone';
import moment from 'moment/moment.js';
import { useEffect, useState } from 'react';
import { Badge, Button, Card, Col, ListGroup, Row, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import EventModalMediaContent from '../../components/EventModalMediaContent';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import SubtleBadge from '../../components/common/SubtleBadge';
import { useGetOrderDetailsQuery, usePayOrdersMutation } from '../../slices/ordersApiSlice';
import OrderDetailOperationCard from './OrderDetailOperationCard';
import OrderPaymentScheduleNode from './OrderPaymentScheduleNode';
import OrderDevlieryStatus from './OrderDevlieryStatus';
import { toast } from 'react-toastify';

const OrderDetailScreen = () => {
	const { id: orderId } = useParams();
	const { userInfo } = useSelector((state) => state.auth);
	const { data: order, refetch, isLoading, error, isFetching: isOrderFetching } = useGetOrderDetailsQuery(orderId);
	const navigate = useNavigate();

	const [placeOrderNode, setPlaceOrderNode] = useState([]);
	const [deliverNode, setDeliverNode] = useState([]);
	const [acceptNode, setAcceptNode] = useState([]);

	const [payOrder, { isLoading: loadingPay }] = usePayOrdersMutation();
	// const [placeOrderAR, setPlaceOrderAR] = useState({});

	// const placeOrderCalculatedDays = timeZone.tz(moment(placeOrderAR?.expire_time), 'Asia/Shanghai').diff(timeZone.tz(moment(), 'Asia/Shanghai'), 'days');
	const onPayOrder = async (accountsReceivable, title, orderOId, orderId) => {
		await payOrder({ orderOId, details: { payAccountsReceivable: accountsReceivable, title } });
		refetch();
		toast.success(orderId + '订单' + title + '款项已核销!');
	};

	useEffect(() => {
		// setPlaceOrderAR(order?.accountsReceivables.filter((a) => a.title === '预付')[0]);
		setPlaceOrderNode(order?.accountsReceivables.filter((node) => node.title === '预付'));
		setDeliverNode(order?.accountsReceivables.filter((node) => node.title === '发货/提供服务' && !node.isPaid));
		setAcceptNode(order?.accountsReceivables.filter((node) => node.title === '验收' && !node.isPaid));
	}, [order?.accountsReceivables]);

	return isLoading ? (
		<Loader />
	) : error ? (
		<Message variant='danger'></Message>
	) : (
		<>
			<Row className='g-3'>
				<Col xs={12}>
					<Card className='mb-1'>
						<Card.Body>
							<Row className='flex-between-center'>
								<Col md={6}>
									<h5 className='my-2 mb-md-0 me-1'>
										订单编号 # {order.orderId}
										{order.approvalStatus === 'inProcessOfApproval' && (
											<Badge className='ms-1 mb-1 p-1 fs-11 align-middle ' bg='danger'>
												<FontAwesomeIcon icon='fa-rotate' spin spinReverse className='me-1' />
												<span>待审批</span>
											</Badge>
										)}
										{order.approvalStatus === 'approved' && !order.isPlaceOrderPaid && (
											<Badge className='ms-1 mb-1 p-1 fs-11 align-middle' bg='success'>
												<FontAwesomeIcon icon='check' className='me-1' />
												<span>审批通过</span>
											</Badge>
										)}
										{order.approvalStatus === 'reject' && (
											<Badge className='ms-1 mb-1 p-1 fs-11 align-middle' bg='danger'>
												<FontAwesomeIcon icon='xmark' className='me-1' />
												<span>被驳回</span>
											</Badge>
										)}
										{placeOrderNode && placeOrderNode.length !== 0 && !placeOrderNode[0]?.isPaid && (
											<Badge className='ms-1 mb-1 p-1 fs-11 align-middle' bg='warning'>
												<FontAwesomeIcon icon='fa-user-clock' className='me-1' />
												待确认预付款
											</Badge>
										)}
										{deliverNode && deliverNode.length !== 0 && !deliverNode[0]?.isPaid && (
											<Badge className='ms-1 mb-1 p-1 fs-11 align-middle' bg='warning'>
												<FontAwesomeIcon icon='fa-user-clock' className='me-1' />
												待确认发货款
											</Badge>
										)}
										{acceptNode && acceptNode.length !== 0 && !acceptNode[0]?.isPaid && (
											<Badge className='ms-1 mb-1 p-1 fs-11 align-middle' bg='warning'>
												<FontAwesomeIcon icon='fa-user-clock' className='me-1' />
												待确认验收款
											</Badge>
										)}
										{!order.isDelivered && order.isPlaceOrderPaid && (
											<Badge className='ms-1 mb-1 p-1 fs-11 align-middle' bg='warning'>
												<FontAwesomeIcon icon='fa-people-carry-box' className='me-2' />
												待发货/安装/提供服务
											</Badge>
										)}
										{!order.isAccepted && order.isDeliveryPaid && (
											<Badge className='ms-1 mb-1 p-1 fs-11 align-middle' bg='warning'>
												<FontAwesomeIcon icon='fa-list-check' className='me-1' />
												待验收
											</Badge>
										)}
										{!order.isCompleted ? (
											<Badge className='ms-1 mb-1 p-1 fs-11 align-middle' bg='warning'>
												<FontAwesomeIcon icon='fa-clipboard-question' className='me-2' />
												待完成订单
											</Badge>
										) : (
											<Badge className='ms-1 mb-1 p-1 fs-11 align-middle' bg='success'>
												<FontAwesomeIcon icon='check-circle' className='me-1' />
												订单已全部完成
											</Badge>
										)}
									</h5>
								</Col>

								<Col xs='auto'>
									{order.approvalStatus === 'reject' && (
										<>
											<Button variant='outline-success' className='me-2'>
												<FontAwesomeIcon icon='rotate' className='me-2' />
												修改并再次申请
											</Button>
										</>
									)}

									<Button variant='outline-primary' className='me-2' onClick={() => navigate(`/${userInfo.role}/orderlist`)}>
										<FontAwesomeIcon icon='list' className='me-2' />
										<span>订单列表</span>
									</Button>
									<Button variant='outline-primary' className='me-2' onClick={() => navigate(-1)}>
										<FontAwesomeIcon icon='fa-regular fa-window-restore' className='me-2' />
										<span>返回上一页</span>
									</Button>
									<Button variant='outline-primary' className='me-2' onClick={() => navigate(`/dashboard`)}>
										<FontAwesomeIcon icon='home' className='me-2' />
										<span>首页</span>
									</Button>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Col>
				<Col>
					<Card>
						<Card.Header as='h6' className='bg-body-tertiary'>
							<Card.Title className='mb-0'>订单详情</Card.Title>
						</Card.Header>
						<Row>
							<Col>
								<ListGroup variant='flush'>
									<ListGroup.Item>
										<Row>
											<Col>
												<p>
													<strong>客户名称: </strong>
													{order.customerInfo.customerName}
												</p>
												{order.customerInfo.staffName && (
													<p>
														<strong>联系人: </strong>
														{order.customerInfo.staffName}
													</p>
												)}
												{order.customerInfo.staffPhone && (
													<p>
														<strong>电话: </strong>
														{order.customerInfo.staffPhone}
													</p>
												)}
												{order.customerInfo.staffWechat && (
													<p>
														<strong>微信: </strong>
														{order.customerInfo.staffWechat}
													</p>
												)}
												{order.customerInfo.staffEmail && (
													<p>
														<strong>邮箱: </strong>
														<a href={`mailto:${order.customerInfo.staffEmail}`}>{order.customerInfo.staffEmail}</a>
													</p>
												)}
												<p>
													<strong>地址: </strong>
													{order.customerInfo.customerAddress}
												</p>
											</Col>
											<Col>
												<ListGroup variant='flush'>
													<ListGroup.Item key='saleInfo'>
														<p>
															<strong>业务联系人: </strong>
															{order.saleUser.name}
														</p>
														{order.saleUser.phone && (
															<p>
																<strong>电话: </strong>
																{order.saleUser.phone}
															</p>
														)}
														{order.saleUser.email && (
															<p>
																<strong>邮箱: </strong>
																<a href={`mailto:${order.saleUser.email}`}>{order.saleUser.email}</a>
															</p>
														)}
													</ListGroup.Item>
													<ListGroup.Item key='assistantInfo'>
														<p>
															<strong>销售助理: </strong>
															{order.assistantUser.name}
														</p>
														{order.assistantUser.phone && (
															<p>
																<strong>电话: </strong>
																{order.assistantUser.phone}
															</p>
														)}
														{order.assistantUser.email && (
															<p>
																<strong>邮箱: </strong>
																<a href={`mailto:${order.assistantUser.email}`}>{order.assistantUser.email}</a>
															</p>
														)}
													</ListGroup.Item>
												</ListGroup>
											</Col>
										</Row>
									</ListGroup.Item>

									<ListGroup.Item key='orderNotes'>
										<h5 className='my-3'>
											<strong>
												订单备注 <FontAwesomeIcon icon='fa-regular fa-comment-dots' className='text-primary' />
											</strong>
										</h5>
										<Row>
											<Col>
												{order.orderNotes.length !== 0 ? (
													order.orderNotes.map((item, index) => {
														return (
															<Row className='mb-2' key={index}>
																<Col sm='6'>
																	<FontAwesomeIcon icon='fa-circle-user' className='text-primary me-1 fs-11' />
																	<span className='fs-11 me-1'>{item.user_name}</span>
																	<span className='fs-11 me-1'>{timeZone.tz(moment(item?.create_time).locale('zh-cn'), 'Asia/Shanghai').format('lll')}</span>
																	<FontAwesomeIcon icon='fa-regular fa-comment-dots' className='text-primary fs-10 me-3' />
																	<span className='fs-10'>{item.note}</span>
																</Col>
															</Row>
														);
													})
												) : (
													<p>无订单备注</p>
												)}
											</Col>
										</Row>
									</ListGroup.Item>

									<ListGroup.Item key='orderPayment'>
										<h5 className='my-3'>
											<strong>货款支付</strong>
										</h5>
										<Row key='firstRow'>
											<Col>{order.totalPrice && <EventModalMediaContent icon='fa-regular fa-comment-dots' heading='订单总价' content={`¥${order.totalPrice}`} />}</Col>

											<Col>{order.paymentMethod && <EventModalMediaContent icon='fa-regular fa-comment-dots' heading='支付方式' content={`${order.paymentMethod}`} />}</Col>
										</Row>
										{order.paymentMethod === '全额支付' && (
											<>
												<Row className='mb-3' key='payAll'>
													<EventModalMediaContent icon='clock' heading='支付金额'>
														<span className='me-3 fw-semibold'>¥ {order.accountsReceivables.filter((a) => a.title === '预付')[0].accountsReceivable}</span>
														<SubtleBadge className='me-2'>{order.accountsReceivables.filter((a) => a.title === '预付')[0].paymentPercent} %</SubtleBadge>
														<span>
															{order.accountsReceivables.filter((a) => a.title === '预付')[0] &&
															order.isApproved &&
															order.accountsReceivables.filter((a) => a.title === '预付')[0].isPaid ? (
																<Badge className='me-3' bg='success'>
																	已收款
																</Badge>
															) : (
																<Badge className='me-3' bg='warning'>
																	待确认
																</Badge>
															)}
															{order?.accountsReceivables?.map((ar) => {
																if (!ar.isPaid && ar.title === '预付') {
																	return (
																		<Button
																			size='sm'
																			className='fs-11'
																			variant='outline-warning'
																			onClick={() => onPayOrder(ar.accountsReceivable, ar.title, order._id, order.orderId)}
																		>
																			<FontAwesomeIcon icon='fa-solid fa-stamp' className='me-2' />
																			确认已收{ar.title}款 {loadingPay && <Spinner size='sm'></Spinner>}
																		</Button>
																	);
																} else {
																	return <></>;
																}
															})}
															{order.accountsReceivables.filter((a) => a.title === '预付')[0] && order.accountsReceivables.filter((a) => a.title === '预付')[0].isPaid && (
																<>
																	<FontAwesomeIcon icon='check' className='text-success me-2' />
																	<span className='text-success-emphasis fw-semibold fs-11 '>
																		{order.accountsReceivables.filter((a) => a.title === '预付')[0].title}款已支付在{' '}
																		{timeZone.tz(moment(order.accountsReceivables.filter((a) => a.title === '预付')[0].paidAt), 'Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss')}
																	</span>
																</>
															)}
														</span>
													</EventModalMediaContent>
												</Row>
												<Row className='mb-3' key='deliveryMark'>
													<EventModalMediaContent icon='clock' heading='物流标记'>
														<OrderDevlieryStatus order={order} />
													</EventModalMediaContent>
												</Row>
											</>
										)}
										{order.paymentMethod === '部分支付' && order.prePaymentOption === '百分比' && (
											<>
												{order.percentPayment !== 0 && order.accountsReceivables?.filter((a) => a.title === '预付')[0] ? (
													<EventModalMediaContent icon='fa-regular fa-comment-dots' heading='预付金额'>
														<span className='me-2'> ¥{order.percentPayment} </span>
														<span className='fs-10'>
															<SubtleBadge className='me-2'>{order.prePaymentPercent}%</SubtleBadge>
														</span>

														<span className='fs-10'>
															{order.accountsReceivables.filter((a) => a.title === '预付')[0].isPaid ? (
																<Badge className='me-3' bg='success'>
																	已收款
																</Badge>
															) : (
																order.isApproved && (
																	<Badge className='me-3' bg='warning'>
																		待确认
																	</Badge>
																)
															)}
															{order?.accountsReceivables?.map((ar) => {
																if (!ar.isPaid && ar.title === '预付') {
																	return (
																		<Button
																			size='sm'
																			className='fs-11'
																			variant='outline-warning'
																			onClick={() => onPayOrder(ar.accountsReceivable, ar.title, order._id, order.orderId)}
																		>
																			<FontAwesomeIcon icon='fa-solid fa-stamp' className='me-2' />
																			确认已收{ar.title}款 {loadingPay && <Spinner size='sm'></Spinner>}
																		</Button>
																	);
																} else {
																	return <></>;
																}
															})}
															{order.accountsReceivables.filter((a) => a.title === '预付')[0].isPaid && (
																<>
																	<FontAwesomeIcon icon='check' className='text-success me-2' />
																	<span className='text-success-emphasis fw-semibold fs-11 '>
																		{order.accountsReceivables.filter((a) => a.title === '预付')[0].title}款已支付在{' '}
																		{timeZone.tz(moment(order.accountsReceivables.filter((a) => a.title === '预付')[0].paidAt), 'Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss')}
																	</span>
																</>
															)}
														</span>
													</EventModalMediaContent>
												) : (
													<EventModalMediaContent icon='fa-regular fa-comment-dots' heading='预付金额'>
														<FontAwesomeIcon icon='check' className='text-success me-2' />
														<span className='text-success-emphasis fw-semibold fs-11'>订单无需支付预付款</span>
													</EventModalMediaContent>
												)}
											</>
										)}
										{order.paymentMethod === '部分支付' && order.scheduleNodes.length !== 0 && (
											<>
												<h6 className='my-3'>
													<strong>尾款计划</strong>
												</h6>
												<OrderPaymentScheduleNode order={order} refetch={refetch} />
											</>
										)}

										{order.paymentMethod === '部分支付' && order.prePaymentOption === '预付款' && (
											<>
												<Row className='mb-2'>
													<Col>预支付:</Col>
													<Col>
														<strong>¥ {order.prePayment}</strong>
													</Col>
												</Row>
												<Row className='mb-2'>
													<Col>剩余尾款:</Col>
													<Col>
														<strong>¥ {order.prePaymentBalance}</strong>
													</Col>
												</Row>
											</>
										)}
									</ListGroup.Item>
									<ListGroup.Item className='mb-5' key='orderList'>
										<h5 className='my-3'>
											<strong>订单列表</strong>
										</h5>
										<ListGroup.Item className='bg-primary'>
											<Row className='text-white'>
												<Col>
													<strong>货号</strong>
												</Col>
												<Col>
													<strong>设备名称</strong>
												</Col>
												<Col>
													<strong>数量</strong>
												</Col>
												<Col>
													<strong>单价</strong>
												</Col>
												<Col>
													<strong>小计</strong>
												</Col>
											</Row>
										</ListGroup.Item>
										{order.orderItems.map((item, index) => (
											<>
												<ListGroup.Item key={index} variant={item.isDiscount && 'danger'} data-bs-theme='light' as={Link} to='#!' action>
													<Row>
														<Col>
															{item.productCode}
															{item.isDiscount && <FontAwesomeIcon icon='star' className='text-danger fs-10 ms-1' />}
														</Col>
														<Col>{item.name}</Col>
														<Col>{item.qty}</Col>
														<Col>¥{item.sellingPrice}</Col>
														<Col>¥{(item.qty * item.sellingPrice).toFixed(2)}</Col>
													</Row>
												</ListGroup.Item>
											</>
										))}
									</ListGroup.Item>
								</ListGroup>
							</Col>
						</Row>
					</Card>
				</Col>
				{order.isApproved && (
					<Col md={4}>
						<OrderDetailOperationCard
							order={order}
							refetch={refetch}
							placeOrderNode={placeOrderNode}
							deliverNode={deliverNode}
							acceptNode={acceptNode}
							isOrderFetching={isOrderFetching}
						/>
					</Col>
				)}
			</Row>
		</>
	);
};

export default OrderDetailScreen;
