import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Flex from './common/Flex';

const getCircleStackIcon = (icon, transform) => (
	<span className='fa-stack ms-n1 me-3'>
		<FontAwesomeIcon icon='circle' className='text-200 fa-stack-2x' />
		<FontAwesomeIcon icon={icon} transform={transform ?? ''} className='text-primary fa-stack-1x' inverse />
	</span>
);

const EventModalMediaContent = ({ icon, heading, content, children }) => (
	<Flex className='mt-3'>
		{getCircleStackIcon(icon)}
		<div className='flex-1'>
			<h6>{heading}</h6>
			{children || <p className='mb-0 text-justify'>{content}</p>}
		</div>
	</Flex>
);

export default EventModalMediaContent;
