import React from 'react';
import { Col, Placeholder, Row } from 'react-bootstrap';

const ModalLoading = () => {
	return (
		<>
			<Row>
				<Col>
					<Placeholder as='p' animation='glow'>
						<Placeholder xs={3} bg='primary' />
					</Placeholder>
					<Placeholder as='p' animation='glow'>
						<Placeholder xs={6} bg='primary' />
					</Placeholder>
					<Placeholder as='p' animation='glow'>
						<Placeholder xs={3} bg='primary' />
					</Placeholder>
					<Placeholder as='p' animation='glow'>
						<Placeholder xs={6} bg='primary' />
					</Placeholder>
					<Placeholder as='p' animation='glow'>
						<Placeholder xs={8} bg='primary' />
					</Placeholder>
				</Col>
				<Col>
					<Placeholder as='p' animation='glow'>
						<Placeholder xs={3} bg='primary' />
					</Placeholder>
					<Placeholder as='p' animation='glow'>
						<Placeholder xs={6} bg='primary' />
					</Placeholder>
					<Placeholder as='p' animation='glow'>
						<Placeholder xs={3} bg='primary' />
					</Placeholder>
					<Placeholder as='p' animation='glow'>
						<Placeholder xs={6} bg='primary' />
					</Placeholder>
					<Placeholder as='p' animation='glow'>
						<Placeholder xs={8} bg='primary' />
					</Placeholder>
				</Col>
			</Row>
			<Row>
				<Placeholder as='p' animation='glow'>
					<Placeholder xs={12} bg='primary' />
				</Placeholder>
				<Placeholder as='p' animation='glow'>
					<Placeholder xs={12} bg='primary' />
				</Placeholder>
				<Placeholder as='p' animation='glow'>
					<Placeholder xs={12} bg='primary' />
				</Placeholder>
				<Placeholder as='p' animation='glow'>
					<Placeholder xs={12} bg='primary' />
				</Placeholder>
				<Placeholder as='p' animation='glow'>
					<Placeholder xs={12} bg='primary' />
				</Placeholder>
			</Row>
		</>
	);
};

export default ModalLoading;
